
import { AxiosConfig } from "./AxiosConfig";

export const checkPhone = async (bodyData) => {
    return await AxiosConfig.post('/auth/user/check-phone', bodyData)
}

export const verifyPhone = async (bodyData) => {
    return await AxiosConfig.post('/auth/user/verify-phone', bodyData)
}

export const addUser = async (bodyData) => {
    return await AxiosConfig.post('/auth/user/add-user', bodyData)
}

export const getUserDetails = async (bodyData) => {
    return await AxiosConfig.post('/auth/getUserDetails', bodyData)
}

export const resendOtpDetails = async (phone) => {
    return await AxiosConfig.post(`/auth/getOtp?phone=${phone}`)
}
