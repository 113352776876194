import { createContext, useState } from "react";

const OrderState = createContext()

const OrderContext = (props) => {
    const [getAllOrder, setGetAllOrders] = useState([])

    const updateGetOrders = (bodyData) => {
        setGetAllOrders(bodyData)
    }

    return(
        <OrderState.Provider value={{getAllOrder, updateGetOrders}}>
            {props.children}
        </OrderState.Provider>

    )
}
 export  {OrderContext, OrderState}