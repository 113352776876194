import { createContext, useState } from "react";

const CartStates = createContext();

const CartContext = (props) => {
    const [cartItems, setCartItems] = useState([]);
    const [getCategories, setGetCategories] = useState([]);
    const [cartLength, setCartLength] = useState(0);

    const updateCart = (update) => {
        setCartItems(update);
    };
    const getAllCategoriesData = (updateFunction) => {
        if (typeof updateFunction !== 'function') {
            console.error("Expected a function as updateFunction, but got:", updateFunction);
            return;
        }

        setGetCategories(prevCategories => updateFunction(prevCategories));
    };

    const updateCartItems = (items) => {
        setCartItems(items);
        setCartLength(items.length);
    };

    return (
        <CartStates.Provider value={{ cartItems, updateCart, getAllCategoriesData, updateCartItems, getCategories, cartLength }}>
            {props.children}
        </CartStates.Provider>
    );
}

export { CartStates, CartContext };
