import { BrowserRouter, Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import Login from "./pages/login/Login";
import Register from "./pages/login/Register";
import Reset from "./pages/login/Reset";
import Logout from "./pages/login/Logout";
import Dashboard from "./pages/DashBoard";
import ProductList from "./pages/product/ProductList";
import ProductDetails from "./pages/product/ProductDetails";
import Coupon from "./pages/coupon/coupon";
import MyProfiler from "./pages/profile/MyProfile";
import OrderDetails from "./pages/order/OrderDetails";
import OrderPage from "./pages/order/OrdersPage";
import Navbar from "./pages/Navbar";
import Header from "./pages/Header";
import Address from "./pages/address/Address";
import AddressBox from "./pages/address/AddressBox";
import NewAddress from "./pages/address/NewAddress";
import Wishlist from "./pages/cart/Wishlist";
import Notification from "./pages/profile/Notification";
import Cartpage from "./pages/cart/Cartpage";
import Footer from "./pages/Footer";
import ProductReview from "./pages/product/ProductReview";
import RateProduct from "./pages/product/RatingProduct";
import ShoppingCart from "./pages/checkout/Shipping";
import AuthContext, { Auth } from "./Context/AuthContext";
import OrderConfirmed from "./pages/order/OrderConfirmed";
import { ProductContext } from "./Context/ProductContext";
import { AddressContext } from "./Context/AddressContext";
import { CartContext } from "./Context/CartContext";
import { SearchContext } from "./Context/SearchContext";
import { ReviewContext } from "./Context/ReviewContext";
import { OfferContext } from "./Context/OffersContext";
import { ToastMessageContext } from "./Context/ToastMessageContext";
import { OrderContext } from "./Context/OrderContext";

// Layout Components
const NoLayout = ({ children }) => <>{children}</>;
const NavbarLayout = ({ children }) => (
  <>
    <Navbar />
    {children}
    <Footer />
  </>
);
const DashboardLayout = ({ children }) => (
  <>
    <Header />
    <div className="pt-10"></div>
    <Navbar isSearchOn={true} clearNavbarStake={true} />
    {children}
    <Footer />
  </>
);

const PrivateRoute = ({ children }) => {
  const AuthUserContext = useContext(Auth);
  const navigate = useNavigate();

  if (AuthUserContext.customerId === 0) {
    navigate("/login");
    return null; // Prevent rendering the children while redirecting
  }

  return children;
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null; // No rendering needed
};

const AppComponent = () => (
  <>
    <ScrollToTop />
    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<NoLayout><Login /></NoLayout>} />
      <Route path="/register" element={<NoLayout><Register /></NoLayout>} />
      <Route path="/reset" element={<NoLayout><Reset /></NoLayout>} />
      <Route path="/Logout" element={<NoLayout><Logout /></NoLayout>} />
      <Route path="/OrderConfirmed" element={<NoLayout><OrderConfirmed /></NoLayout>} />

      {/* Routes with Navbar */}
      <Route path="/" element={<DashboardLayout><Dashboard /></DashboardLayout>} />
      <Route path="/ProductList" element={<DashboardLayout><ProductList /></DashboardLayout>} />

      <Route path="/ProductDetails" element={
        <DashboardLayout>
          <ProductDetails />
        </DashboardLayout>
      } />


      <Route path="/MyProfiler" element={<NavbarLayout><MyProfiler /></NavbarLayout>} />
      <Route path="/Coupon" element={<NavbarLayout><Coupon /></NavbarLayout>} />
      <Route path="/WishList" element={<NavbarLayout><Wishlist /></NavbarLayout>} />
      <Route path="/Notification" element={<NavbarLayout><Notification /></NavbarLayout>} />

      {/* Protected Routes */}
      <Route path="/OrderPage" element={<PrivateRoute><NavbarLayout><OrderPage /></NavbarLayout></PrivateRoute>} />
      <Route path="/OrderDetails" element={<PrivateRoute><NavbarLayout><OrderDetails /></NavbarLayout></PrivateRoute>} />
      <Route path="/Address" element={<PrivateRoute><NavbarLayout><Address /></NavbarLayout></PrivateRoute>} />
      <Route path="/AddressBox" element={<PrivateRoute><NavbarLayout><AddressBox /></NavbarLayout></PrivateRoute>} />
      <Route path="/NewAddress" element={<PrivateRoute><NavbarLayout><NewAddress /></NavbarLayout></PrivateRoute>} />
      <Route path="/Cartpage" element={<PrivateRoute><NavbarLayout><Cartpage /></NavbarLayout></PrivateRoute>} />
      <Route path="/ProductReview" element={<PrivateRoute><NavbarLayout><ProductReview /></NavbarLayout></PrivateRoute>} />
      <Route path="/RateProduct" element={<PrivateRoute><NavbarLayout><RateProduct /></NavbarLayout></PrivateRoute>} />
      <Route path="/check-out" element={<PrivateRoute><NavbarLayout><ShoppingCart /></NavbarLayout></PrivateRoute>} />

      {/* Fallback for undefined routes */}
      <Route path="*" element={<NavbarLayout><h3>Page not found</h3></NavbarLayout>} />
    </Routes>
  </>
);

const App = () => (
  <AuthContext>
    <ProductContext>
      <AddressContext>
        <CartContext>
          <OrderContext>
            <ReviewContext>
              <SearchContext>
                <OfferContext>
                  <BrowserRouter>
                    <ToastMessageContext>
                      <AppComponent />
                    </ToastMessageContext>
                  </BrowserRouter>
                </OfferContext>
              </SearchContext>
            </ReviewContext>
          </OrderContext>
        </CartContext>
      </AddressContext>
    </ProductContext>
  </AuthContext>
);

export default App;
