import React, { useState } from 'react';

const ImageWithLoader = ({ src, alt, containerClass, imgClass }) => {
  const [loading, setLoading] = useState(true);

  // Handle image load and introduce a short delay before setting loading to false
  const handleImageLoad = () => {
    setTimeout(() => {
      setLoading(false);
    }, 500); 
  };

  return (
    <div className={`relative overflow-hidden p-2 ${containerClass}`}>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
          <div className="loader">
            <svg
              className="animate-spin h-10 w-10 text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              />
            </svg>
          </div>
        </div>
      )}

      {/* Image element with smooth transition for blur and scale effects */}
      <img
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        className={`
          transition-all duration-500 ease-in-out 
          ${loading ? 'blur-sm scale-110' : 'blur-0 scale-100'} 
          ${imgClass}
        `}
      />
    </div>
  );
};

export default ImageWithLoader;
