import { AxiosConfig } from "./AxiosConfig";

export const getallCategories = async () => {
    return await AxiosConfig.get('/products/get/get-categories')
}

export const getAllProducts = async (bodyData) => {
    return await AxiosConfig.post('products/get/all-products',  bodyData )
}

export const getProductDetails = async (bodydata) => {
    return await AxiosConfig.post('products/get/get-details', bodydata)
}

// top selling products

export const getTopSellingProducts = async () => {
    return await AxiosConfig.post('products/get/get-homepage')
}

// Search Products main search bar
export const searchProducts = async (search) => {
    return await AxiosConfig.post('/products/searchProduct', null, {
        params: {
            productSearch: search
        }
    })
}