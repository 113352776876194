import React, { useState, useContext } from "react";
import loginsecond from "../../assets/Login2.png";
import MAINIMAGE from "../../assets/MAINIMAGE.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { addUser } from "../../Networking/AuthApisActions";
import { Auth } from "../../Context/AuthContext";
import Loader from "../../acscomponents/Loader";
import ImageWithLoader from "../../acscomponents/ImageLoaderComponent";
import CryptoJS from "crypto-js";

const Register = () => {
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    phoneNumber: "",
    otp: "",
    checkbox: true,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const authUserContext = useContext(Auth);
  const navigate = useNavigate();
  const location = useLocation();
  const { phoneNumber, userId } = location?.state || {};



  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validateUsername = (userName) => {
    const userNameRegex = /^[A-Za-z0-9]+$/;
    return userNameRegex.test(userName)
  }

  const validateForm = () => {
    let errors = {};

    if (!formData.userName) {
      errors.userName = "Username is required";
    } else if (!validateUsername(formData.userName)) {
      errors.userName = "Invalid username format";
    }

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      errors.email = "Invalid email format";
    }

    if (!formData.checkbox) {
      errors.checkbox = "You must agree to the terms and conditions";
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };



  const addUserApi = async () => {
    if (userId !== 0 || null && phoneNumber !== 0 || null) {
      const bodyDataAdduser = {
        id: userId,
        name: formData.userName,
        email: formData.email,
        phone: phoneNumber,
        otp: authUserContext.userData.otp ? authUserContext.userData.otp : 0,
      };

      addUser(bodyDataAdduser).then((response) => {
        const userResponsedata = response.data.data;

        if (response.data.code === 200 && userResponsedata.registered === true) {
          authUserContext.updateUserData(userResponsedata);
          const encryptedUserId = CryptoJS.AES.encrypt(userId.toString(), 'UserKey').toString();
          localStorage.setItem('UserKey', encryptedUserId);
          authUserContext.setCustomerId(userId);
          navigate("/");
        }

        console.log(userResponsedata, "userResponsedata");
        console.log(authUserContext.userData, "userDatauserData");

      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      addUserApi();
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader className={loading ? "blur" : "blur-none"} />}
      <div
        className="min-h-screen bg-gray-100 flex lg:px-32 md:px-16"
        style={{
          backgroundImage: `url(${MAINIMAGE})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="hidden md:block md:flex-1 md:py-10">
          <div className="bg-light-brown h-full">
            <div className="text-center text-white flex flex-col justify-around h-full">
              <div>
                <h2 className="text-28 font-jaldi font-bold">
                  Create Account
                </h2>
                <p className="text-25 font-jaldi">
                  Please register account details
                </p>
              </div>
              <ImageWithLoader
                src={loginsecond}
                alt="Illustration"
                containerClass="mx-auto py-7"
              />
            </div>
          </div>
        </div>
        <div className="flex-1 md:py-10">
          <div className="w-full bg-white lg:px-20 md:px-10 h-full flex flex-col justify-center">
            <form className="space-y-1 px-5" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="userName"
                  className="block text-23 py-2 font-jaldi font-medium"
                >
                  Username
                </label>
                <input
                  type="text"
                  id="userName"
                  name="userName"
                  value={formData.userName}
                  onChange={handleInputChange}
                  className="w-full px-4 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-dark-brown focus:border-dark-brown"
                  placeholder="Username"
                />
                {error.userName && (
                  <p className="text-sm text-red-500">{error.userName}</p>
                )}
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-23 py-2 font-jaldi font-medium"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full px-4 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-dark-brown focus:border-dark-brown"
                  placeholder="Email"
                />
                {error.email && (
                  <p className="text-sm text-red-500">{error.email}</p>
                )}
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="checkbox"
                  name="checkbox"
                  checked={formData.checkbox}
                  onChange={handleInputChange}
                  className="h-5 w-5 text-dark-brown focus:ring-dark-brown border-gray-300 rounded -mt-2"
                  required
                />
                <label
                  htmlFor="checkbox"
                  className="ml-2 block text-16 mt-4 mb-5 font-jaldi text-gray-900"
                >
                  I have read and agree with the terms & conditions
                </label>
              </div>
              {error.checkbox && (
                <p className="text-sm text-red-500">{error.checkbox}</p>
              )}
              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  className="px-6 py-2 text-white bg-dark-brown text-18 font-jaldi rounded-3xl"
                >
                  Sign Up
                </button>
              </div>
            </form>
            <div className="text-center mt-2">
              <p className="text-18 font-jaldi mb-5">
                Already have an account?{" "}
                <Link
                  to={"/login"}
                  className="font-bold text-dark-brown hover:text-brown-500"
                >
                  Log in
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
