import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import ProductImg from "../../assets/Rectangle 390.png";
import OrderTrackingH from "../../acscomponents/OrderTrackingH";
import { Auth } from "../../Context/AuthContext";
import {getAddress } from "../../Networking/AddressApisActions";
import Breadcrumb from "../product/Breadcrumb";

const OrderDetails = () => {
  const location = useLocation()
  const {orderId} = location.state || {}
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);
  const [addresses, setAddresses] = useState([])
  const AuthUserContextState = useContext(Auth)

  useEffect(() => {      
    if(AuthUserContextState.customerId){
  const bodyData = AuthUserContextState.customerId
      getAddress(bodyData).then(response => {
        const data = response.data.data
        const defaultAddress = data.find(address => address.default === true) || data [0]
        if(defaultAddress){setAddresses([defaultAddress])}
      })
    }   
  },[]) 

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };
  const handleClickOutside = (event) => {
    if (contentRef.current && !contentRef.current.contains(event.target)) {setIsVisible(false);}
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener("click", handleClickOutside, true);
    } else {
      document.removeEventListener("click", handleClickOutside, true);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isVisible]);

  return (
    <>    
      <div className="px-0 md:px-40px lg:px-90px">
        <div className="flex flex-row font-Inter font-medium tracking-wide text-15px pt-3 md:pt-46px pb-3">
          <div className="flex text-nowrap items-center gap-3">
            <Breadcrumb />
            <span className="text-dark-brown-opacity-60">{orderId ? orderId : null}</span>
          </div>
        </div>
        <div className="flex flex-col md:flex-row shadow-sm bg-white justify-between font-Inter tracking-wide pt-3">
          { addresses.map((item, index) => (
            <div key={index} className="flex flex-col flex-1">
              <div className="ps-4 pe-2 md:ps-36px md:pe-36px">
                <h6 className="text-dark-brown text-15px font-normal">Delivery Address</h6>
                <div className="pt-2 pb-5 text-15px font-medium">
                  <p>{item.name}</p>
                </div>
                <div className="flex flex-col flex-1 text-14px font-normal">
                <p>{item.addressLine1 ? `${item.addressLine1},` : null}</p>
                  <p>{item.addressLine2}</p>
                  <p>{item.city}, {item.state}, {item.pincode}</p>
                </div>
                <div className="flex flex-col md:flex-row  pb-5 text-15px font-normal">
                  <div className="flex-2">Phone number</div>
                  <div className="flex-4">{item.phone ? item.phone : 'N/A'}</div>
                </div>
              </div>
            </div>
          ))}
          <div className="flex flex-col flex-2">
            <div className="ps-0 lg:ps-36">
              <div className="flex flex-row text-15px font-normal text-dark-brown">
                Your Rewards
              </div>
              <div className="flex flex-row pl-56px text-15px font-normal text-black pt-3">
                No Rewards
              </div>
            </div>
          </div>
        </div>

        {/* Product Order Section */}
        <div className="flex flex-col text-center md:flex-row lg:flex-col xl:flex-row  md:text-left justify-center items-center md:items-center md:ps-0 shadow-sm bg-white mt-5 py-2 md:py-9">
          <div className="flex flex-col flex-1 flex-shrink-0 px-28px">
            <img src={ProductImg} alt="" width={150} height={117} />
          </div>
          <div className="flex flex-col flex-1 px-5 md:relative lg:ps-4">
            <div className="flex flex-col gap-2">
              <p className="font-Inter font-medium text-15px">
                Mix Dried Berries
              </p>
              <p className="font-Inter font-medium text-15px text-light-red">
                Rs.300.00
              </p>
              <p
                className="font-quicksand font-semibold text-13px cursor-pointer hover:text-dark-brown"
                onClick={handleToggle}
              >
                Amount details
              </p>

              {/* Amount Details Section */}
              {isVisible && (
                <div
                  ref={contentRef}
                  className="flex flex-col absolute md:bottom-0 left-0 ml-4 md:ml-0 drop-shadow-2xl bg-white"
                >
                  <div className="flex flex-col w-72 md:w-80 lg:w-400px">
                    <h2 className="font-medium text-md pl-24px pt-2 pb-2 font-quicksand text-13px leading-5">
                      Price details
                    </h2>
                    <hr className="border border-black opacity-10" />
                    <div className="flex flex-row pl-24px pr-24px pt-10px font-quicksand text-13px text-black font-medium">
                      <div className="flex-flex-1-50%">
                        <p>Price ( 1 item )</p>
                      </div>
                      <div className="flex-flex-1-50%">
                        <p className="text-right">Rs.300</p>
                      </div>
                    </div>
                    <div className="flex flex-row flex-wrap pl-24px pr-24px pt-10px font-quicksand text-13px text-black font-medium">
                      <div className="flex-flex-1-50%">
                        <p>Tax</p>
                      </div>
                      <div className="flex-flex-1-50%">
                        <p className="text-right pr-5">-</p>
                      </div>
                    </div>

                    <div className="flex flex-row flex-wrap pl-24px pr-24px pt-10px font-quicksand text-13px text-black font-medium">
                      <div className="flex-flex-1-50% ">
                        <p>Discount</p>
                      </div>
                      <div className="flex-flex-1-50%">
                        <p className="text-right">Rs. 50</p>
                      </div>
                    </div>
                    <div className="flex flex-row flex-wrap pl-24px pr-24px pt-10px pb-3 font-quicksand text-13px text-black font-medium">
                      <div className="flex-flex-1-50% ">
                        <p>Delivery Charges</p>
                      </div>
                      <div className="flex-flex-1-50%">
                        <p className="text-right text-green">Free</p>
                      </div>
                    </div>
                    <hr className="border border-black opacity-10" />
                    <div className="flex flex-row flex-wrap pl-24px pr-24px pt-10px pb-3 font-quicksand text-13px text-black font-medium">
                      <div className="flex-flex-1-50% font-quicksand font-medium">
                        <p>Total Amount</p>
                      </div>
                      <div className="flex-flex-1-50%">
                        <p className="text-right text-13px font-bold">
                          Rs. 250
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>


          <div className="flex flex-col flex-5 justify-start items-center md:items-center lg:items-start pt-5">
            <div className="flex flex-col md:flex-col lg:flex-row  font-Inter font-medium text-16px tracking-normal  text-dark-brown pt-10">

            <div className="flex flex-col flex-4 justify-start  pl-12 items-center md:items-center lg:items-start">
            <div className="flex flex-col md:flex-col lg:flex-row  font-Inter font-medium text-16px tracking-normal text-dark-brown">
              <OrderTrackingH stage={[  
                {id : 1, stage: 'Order Confirmed', date: 'Thu, 17th Aug'},
                {id : 2, stage: 'Shipped', date: 'Thu, 17th Aug'},
                {id : 3, stage: 'Out of Delivery', date: 'Sat, 19th Aug'},
                {id : 4, stage: 'Delivered', date: 'Thu, 25th Aug'}
              ]}/>
            </div>
            <div className="flex flex-row justify-center items-center font-Inter font-normal text-15px tracking-wide text-black-opacity-60 pt-5 lg:ps-16">
                <p>Your Item has been Delivered.</p>
            </div>
          </div>
            </div>        
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default OrderDetails;
