import React, { useContext, useEffect, useState } from "react";
import { cardQuantity, removeCart } from "../../Networking/CartApiAction";
import { Auth } from "../../Context/AuthContext";
import { CartStates } from "../../Context/CartContext";
import { useNavigate } from "react-router-dom";
import Loader from "../../acscomponents/Loader";
import { timer } from "../../assets/svg";
import ImageLoaderComponent from "../../acscomponents/ImageLoaderComponent";
import { ToastMsgContext } from "../../Context/ToastMessageContext";
import ToastMessage from "../../acscomponents/ToastMessage";

const Cartpage = () => {
  const [loading, setLoading] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const [expandedItemId, setExpandedItemId] = useState(null);
  const navigate = useNavigate();
  const AuthUserContext = useContext(Auth);
  const cartContext = useContext(CartStates);
  const { getCategories, cartItems, updateCartItems } = cartContext;
  const { getCode, getMessage, getIsActive } = useContext(ToastMsgContext);

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (getCategories && getCategories.length > 0) {
      const sortedItems = [...getCategories].sort(
        (a, b) => a.addedTime - b.addedTime
      );
      updateCartItems(sortedItems);
    }
  }, [getCategories]);

  useEffect(() => {
    const calculateTotalPrice = () => {
      if (Array.isArray(cartItems)) {
        const newTotalPrice = cartItems.reduce(
          (total, item) => total + item.quantity * item.discountedPrice,
          0
        );
        setTotalPrice(newTotalPrice);
      } else {
        setTotalPrice(0);
      }
    };

    calculateTotalPrice();
  }, [cartItems]);

  const handleAddQuantity = (id) => {
    setLoading(true);
    const data = {
      requestId: id,
      type: 1,
    };

    cardQuantity(data)
      .then((res) => {
        if (res.data.code === 200) {
          cartContext.updateCartItems((prev) =>
            prev.map((item) =>
              item.id === id ? { ...item, quantity: item.quantity + 1 } : item
            )
          );
          getIsActive(true);
          getCode(res.data.code);
          getMessage(res.data.message);
          setLoading(false);
        } else {
          console.log("Error updating quantity:", res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("API Error:", err);
        setLoading(false);
      });
  };

  const handleRemoveQuantity = (id) => {
    setLoading(true);
    const data = { requestId: id, type: 2 };

    cardQuantity(data)
      .then((res) => {
        if (res.data.code === 200) {
          cartContext.updateCartItems((prev) =>
            prev.map((item) =>
              item.id === id ? { ...item, quantity: item.quantity - 1 } : item
            )
          );
          getIsActive(true);
          getCode(res.data.code);
          getMessage(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("API Error:", err);
        setLoading(false);
      });
  };

  const handleRemoveItem = (id) => {
    setLoading(true);
    const bodyData = {
      requestId: id,
      userId: AuthUserContext.customerId,
    };

    removeCart(bodyData).then((res) => {
      if (res.data.code === 200) {
        cartContext.updateCartItems((prev) =>
          prev.filter((item) => item.id !== id)
        );
        getIsActive(true);
        getCode(res.data.code);
        getMessage(res.data.message);
        setLoading(false);
      }
    });
  };

  const handleCheckout = () => {
    if (cartItems) {
      navigate("/check-out", {
        state: {
          cartItems: cartItems,
          totalPrice: totalPrice,
        },
      });
    }
  };

  return (
    <>
      {loading && <Loader className={loading ? "blur" : "blur-none"} />}
      <ToastMessage />
      <div className="overflow-hidden no-scroll">
        <div className="flex flex-col md:grid md:grid-cols-3 w-screen md:gap-3 py-2 px-5">
          <div className="col-span-1 md:col-span-2 h-[90vh] bg-white overflow-auto no-scroll">
            {cartItems.length === 0 && (
              <p className="text-xl text-dark-brown font-Inter font-semibold flex justify-center items-center mt-16 uppercase">
                No Cart Available
              </p>
            )}
            {cartItems.length > 0 &&
              cartItems.map((item) => (
                <div key={item.id} className="">
                  <div className="md:grid grid-cols-7 gap-3 h-auto w-full px-3 border-b-2">
                    <div className="md:col-span-2 border-b-2 w-full flex justify-center items-center shadow-indigo-100">
                      <div className="flex md:block text-center md:w-48 h-36">
                        <ImageLoaderComponent
                          containerClass="w-full h-full"
                          imgClass="flex-shrink-0 h-full object-cover md:p-2 md:p-1 justify-center"
                          src={item.productImage || "N/A"}
                          alt="Product Not Found"
                        />
                      </div>
                    </div>
                    <div className="col-span-5 px-5">
                      <div className="">
                        <div className="flex flex-col flex-5">
                          <div className="py-4 text-14px font-black font-Inter space-y-1.5">
                            <p className="capitalize text-md font-QuattrocentoSans tracking-wide text-dark-brown font-black md:text-16px">
                              {item.productName}
                            </p>

                            <div className="flex gap-3 mt-2.5">
                              <p className="text-16px opacity-90 font-QuattrocentoSans font-bold">
                                Description
                              </p>
                              <div className="grid">
                                <span
                                  className="text-sm text-pretty font-normal font-QuattrocentoSans content-span"
                                  dangerouslySetInnerHTML={{
                                    __html: item.description, // Render the HTML content directly
                                  }}
                                />
                              </div>
                            </div>

                            <div className="">
                              <p className="capitalize">
                                price:{" "}
                                <span className="px-3 font-normal">
                                  Rs. {item.quantity * item.discountedPrice}
                                </span>
                              </p>
                              <p className="capitalize">
                                size:{" "}
                                <span className="px-3 font-normal">
                                  {item.productSize || "N/A"}
                                </span>
                              </p>
                              <p className="capitalize flex gap-5 py-2.5 font-semibold text-12px">
                                <div className="flex gap-2">
                                  <button
                                    onClick={() =>
                                      handleRemoveQuantity(item.id)
                                    }
                                    disabled={item.quantity === 1}
                                    className="text-black px-2 font-bold border-black hover:text-white hover:bg-dark-brown font-quicksand text-13px border-opacity-25 cursor-pointer"
                                  >
                                    -
                                  </button>
                                  <p className="text-black px-2 border border-black font-quicksand font-medium text-13px border-opacity-25">
                                    {item.quantity || 1}
                                  </p>
                                  <button
                                    onClick={() => handleAddQuantity(item.id)}
                                    className="text-black px-2 border-black hover:text-white hover:bg-dark-brown font-quicksand font-bold text-13px border-opacity-25"
                                  >
                                    +
                                  </button>
                                </div>
                                <div className="flex justify-center items-center gap-x-2.5">
                                  qty:{" "}
                                  <p className="font-normal text-14px">
                                    {item.quantity || 1}
                                  </p>
                                </div>
                              </p>
                            </div>
                            <p className="capitalize md:grid grid-cols-12 mt-5">
                              <span className="col-span-3 md:col-span-5 me-2">
                                <button className="font-jaldi text-black tracking-wide border-b-2 capitalize font-medium">
                                  save for later
                                </button>
                              </span>
                              <span className="col-span-6 text-center">
                                <button
                                  className="font-jaldi text-black tracking-wide border-b-2 capitalize font-medium"
                                  onClick={() => handleRemoveItem(item.id)}
                                >
                                  Remove
                                </button>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div className="flex-1 md:col-span-1  py-2.5 ">
            <div className="px-5 bg-gray-100  rounded-xl">
              <h2 className="text-center text-2xl font-bold py-3">
                Order Summary
              </h2>
              <div className="flex flex-row flex-wrap justify-between py-3 font-jaldi text-black text-md">
                <div className="flex-1">
                  <p>Subtotal</p>
                </div>
                <div className="flex-1 text-right">
                  <p>Rs. {Array.isArray(cartItems) ? totalPrice : 0}</p>
                </div>
              </div>
              <div className="flex flex-row flex-wrap justify-between py-3 font-jaldi text-black text-md">
                <div className="flex-1">
                  <p>Discount</p>
                </div>
                <div className="flex-1 text-right">
                  <p>Rs. 0.00</p>
                </div>
              </div>
              <div className="flex flex-row flex-wrap justify-between py-3 font-jaldi text-black text-md">
                <div className="flex-1">
                  <p>Delivery Charges</p>
                </div>
                <div className="flex-1 text-right">
                  <p>Rs. 0.00</p>
                </div>
              </div>
              <hr className="border border-black opacity-5 my-2" />
              <div className="flex flex-row flex-wrap justify-between py-3 font-jaldi text-black text-md">
                <div className="flex-1">
                  <p>Total Amount</p>
                </div>
                <div className="flex-1 text-right font-bold">
                  <p>Rs. {totalPrice}</p>
                </div>
              </div>
              <button
                className={`w-full h-10 bg-dark-brown rounded-3xl text-white text-md font-bold tracking-wide my-3 ${
                  cartItems.length === 0 ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handleCheckout}
                disabled={cartItems.length === 0}
              >
                Proceed to Checkout
              </button>
              <div className="shadow-lg w-full mt-3 py-3 px-5">
                <p className="font-bold text-md tracking-wide font-jaldi text-18px leading-5 capitalize">
                  Shipping
                </p>
                <p className="text-sm mt-3 font-semibold">
                  Arrives Tue, Jul 20 - Jul 22
                </p>
                <div className="flex gap-3 mt-5 items-center">
                  <img src={timer} alt="timer" className="w-6" />
                  <p className="text-dark-brown text-sm font-semibold">
                    Just a few left. Order soon.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cartpage;
