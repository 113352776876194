import { useLocation, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { AddReviewApi } from "../../Networking/ReviewApiAction";
import { Auth } from "../../Context/AuthContext";
import Loader from "../../acscomponents/Loader";
import ToastMessage from "../../acscomponents/ToastMessage";
import { ToastMsgContext } from "../../Context/ToastMessageContext";

const RateProduct = ({ productId }) => {
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const AuthUserContextStates = useContext(Auth);
  const userData = AuthUserContextStates.userData;
  const ToastMsgState = useContext(ToastMsgContext);


  
  const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null; // No rendering needed
  };

  const handleSubmit = () => {
    if (rating === 0) {
      setError("Rating is required");
      return;
    }
    if (productId && AuthUserContextStates.customerId !== 0) {
      const { customerId } = AuthUserContextStates;
      const bodyData = {
        productId: productId,
        rating: rating,
        comments: comment,
        userId: customerId,
      };
      AddReviewApi(bodyData).then((response) => {
        const responseCode = response?.data?.code;
        if (response.data.code === 200) {
          ToastMsgState.getMessage(response.data.message);
          ToastMsgState.getCode(responseCode);
          ToastMsgState.getIsActive(true);
          window.location.reload();
          ScrollToTop()
        }
        if (response.data.code === 201) {
          ToastMsgState.getMessage(response.data.message);
          ToastMsgState.getCode(responseCode);
          ToastMsgState.getIsActive(true);
          setLoading(false);
        }
      });
      setComment("");
      setRating("");
    } else {
      ToastMsgState.getMessage("Login  to rate product");
      ToastMsgState.getCode(201);
      ToastMsgState.getIsActive(true);
    }
  };


  const handleStarClick = (index, isHalf) => {
    const newRating = isHalf ? index + 0.5 : index + 1;
    setRating(Math.round(newRating * 2) / 2);
    setError("");
    console.log("New Rating:", Math.round(newRating * 2) / 2);
  };

  const handleMouseMove = (event) => {
    if (!event.target) return false;
    const { left, width } = event.target.getBoundingClientRect();
    const mouseX = event.clientX - left;
    const isHalf = mouseX < width / 2;
    return isHalf;
  };

  return (
    <div className=" py-4 ">
      {loading && <Loader className={loading ? "blur" : "blur-none"} />}
      <ToastMessage />
      <div className="px-5 space-y-5 ">
        <div className="pt-3.5 pb-2 ">
          <p className="text-18px font-Inter text-dark-brown font-semibold text-center lg:text-start">
            Review Product
          </p>
        </div>
        <div className="">
          <div className="flex  justify-center items-center gap-3 md:justify-start md:items-start md:gap-0">
            {[...Array(5)].map((_, index) => {
              const isHalf = rating > index && rating < index + 1;
              const isFull = rating >= index + 1;
              return (
                <div
                  key={index}
                  className="relative w-7 px-0.5 h-10 cursor-pointer "
                  onClick={(e) => handleStarClick(index, handleMouseMove(e))}
                  onMouseMove={(e) => handleMouseMove(e)}
                >
                  <svg
                    className="absolute w-full h-10 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 17.27L18.18 21 16.54 14.36 22 9.27H14.81L12 2 9.19 9.27H2L7.46 14.36 5.82 21z" />
                  </svg>
                  <svg
                    className="absolute w-full  h-10"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      width: "100%",
                      clipPath: isHalf ? "inset(0 50% 0 0)" : "none",
                      color: isFull || isHalf ? "#FFD700" : "transparent",
                      position: "absolute",
                      left: "0",
                    }}
                  >
                    <path d="M12 17.27L18.18 21 16.54 14.36 22 9.27H14.81L12 2 9.19 9.27H2L7.46 14.36 5.82 21z" />
                  </svg>
                </div>
              );
            })}
          </div>
          {error && <p className="text-xs text-red">{error}</p>}
          <p className="text-14px font-medium py-1.5 text-wrap lg:w-2/3 tracking-widest ">
            Share your thoughts,{" "}
            <span className="text-16px font-bold text-dark-brown">
              {userData.name}!
            </span>{" "}
            We'd love to hear your feedback on this product.
          </p>

          <textarea
            className="w-full h-24 p-2 border border-gray-300 rounded-lg resize-none mb-4 outline-none bg-gray-50 focus:ring-2 focus:ring-emerald-500"
            placeholder="Add your comments here..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
        </div>
        <div className="flex gap-8 justify-center lg:justify-start ">
          <button
            className="px-3.5 py-1.5 bg-dark-brown text-white rounded-lg font-semibold hover:bg-light-brown transition duration-150"
            onClick={handleSubmit}
          >
            Submit Review
          </button>
        </div>
      </div>
    </div>
  );
};
export default RateProduct;
