import React, { useContext, useEffect, useState } from 'react';
import { Tickgreen, Tickwhite } from '../assets/svg/index';
import { ToastMsgContext } from '../Context/ToastMessageContext';

const ToastMessage = () => {
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [color, setColor] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  const toastMsgState = useContext(ToastMsgContext);
  const { code: contextCode, message: contextMessage, isActive: contextIsActive, resetToast } = toastMsgState;

  useEffect(() => {
    setMessage(contextMessage);
    setCode(contextCode);
    setIsActive(contextIsActive);
  }, [contextCode, contextMessage, contextIsActive]);

  const removeNotif = () => {
    setNotifications([]);
    setIsVisible(false);
    setMessage('');
    setCode('');
    setIsActive(false);
    
    // Reset the context values
    resetToast(); // Clears the values in the context
};

  const addNotification = (msg) => {
    setNotifications([{ id: Date.now(), text: msg }]);
    setIsVisible(true);
  };

  useEffect(() => {
    if (message) {
      setColor(code === 200);
      addNotification(message);

      const timeout = setTimeout(() => {
        removeNotif();
      }, 1500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [message, code]);

  return (
    <div className="flex z-30 justify-center items-center relative bg-transparent">
      {isVisible && notifications.map((notif) => (
        <Notification key={notif.id} text={notif.text} color={color} />
      ))}
    </div>
  );
};

const Notification = ({ text, color }) => {
  return (
    <div
      className={`text-white fixed rounded-md shadow-xl bottom-8 right-8 transition-transform ease-in-out animate-ToastModal flex ${color ? "bg-dark-brown" : "bg-red"}
      `}
    >
      <p className='flex gap-2 justify-center items-center px-1 md:px-2 tracking-widest py-0.5 md:py-1'>
        <img src={color ? Tickgreen : Tickwhite} alt="Icon" className="mt-1" />
        <span className="text-10px md:text-14px font-semibold tracking-wide">{text}</span>
      </p>
    </div>
  );
};

export default ToastMessage;
