import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import {
  defaultAddressApi,
  getAddress,
} from "../../Networking/AddressApisActions";
import { AddressState } from "../../Context/AddressContext";
import { Auth } from "../../Context/AuthContext";
import Loader from "../../acscomponents/Loader";
import Breadcrumb from "../../pages/product/Breadcrumb";

// Rename the local Modal to something like ConfirmModal to avoid conflict
const ConfirmModal = ({ confirmOnClick, cancelOnClick, className }) => {
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-45 flex items-center justify-center z-50">
      <div className={`bg-white shadow-lg max-w-sm px-6 py-2 ${className}`}>
        <p>Are you sure you want to remove this address?</p>
        <div className="mt-4 mb-2 flex justify-center">
          <button
            className="px-6 text-sm text-gray-700 font-medium"
            onClick={cancelOnClick}
          >
            Cancel
          </button>
          <button
            className="text-red-600 px-6 text-md font-semibold"
            onClick={confirmOnClick}
          >
            Yes, REMOVE
          </button>
        </div>
      </div>
    </div>
  );
};

const Address = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const addressContext = useContext(AddressState);
  const authContext = useContext(Auth);

  const setAsDefault = (addressId) => {
    setIsLoading(true);
    const data = {
      userId: authContext.customerId,
      addressId: addressId,
    };
    defaultAddressApi(data).then((res) => {
      if (res.data.code === 200) {
        addressContext.updateAddress((prev) =>
          prev.map((address) =>
            address.id === addressId
              ? { ...address, default: true }
              : { ...address, default: false }
          )
        );
        setIsLoading(false);
      } else if (res.data.code === 204) {
        setError(res.data.message);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (authContext.customerId) {
      setIsLoading(true);
      getAddress(authContext.customerId, authContext.customerId).then((res) => {
        addressContext.updateAddress(res.data.data);
        setIsLoading(false);
      });
    }
  }, [authContext.customerId]);

  const handleaddress = (address) => {
    setSelectedAddress(address);
    setIsModalOpen(true);
    // if()
  };

  const removeAddress = () => {
    if (selectedAddress) {
      addressContext.updateAddress((prevAddresses) =>
        prevAddresses.filter((address) => address.id !== selectedAddress.id)
      );
      setIsModalOpen(false);
      setSelectedAddress(null);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const editAddress = (address) => {
    navigate("/NewAddress", { state: { address } });
  };

  const addNewAddress = () => {
    navigate("/NewAddress");
  };

  return (
    <div className="pb-8">
      <div className="py-1 mx-auto max-w-5xl border border-black bg-white rounded-sm mt-7 border-opacity-5 shadow-md font-Inter p-4 pb-20">
        {isLoading && <Loader />}
        <h6 className="ml-5 mt-2 text-sm">
          <Breadcrumb />
        </h6>
        <div className="px-4 py-2">
          <div className="flex justify-between items-center mb-7">
            <h2 className="text-17px ml-5 text-yellow font-semibold mb-5 mt-1 md:mb-2">
              Your Address
            </h2>
            <Button
              className="hidden md:block text-yellow py-2 px-3 mr-4 border border-black shadow-md font-semibold rounded-sm border-opacity-35 cursor-pointer"
              onClick={addNewAddress}
              text="Add Address"
            />
            {error && <p className="text-xs text-red font-Inter">{error}</p>}
          </div>
          {addressContext.addressList &&
          addressContext?.addressList.length > 0 ? (
            <div className="h-full md:h-[80vh] no-scrollbar overflow-y-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 px-4">
              {addressContext.addressList.map((address) => (
                <div
                  key={address.id}
                  className={`${
                    address.default
                      ? "text-yellow border-2 rounded-md border-yellow "
                      : "text-black border-2 rounded-md border-yellow border-opacity-30"
                  }`}
                >
                  {(address.default ||
                    address.addressType === "Home" ||
                    address.addressType === "Office") && (
                    <p className="text-white p-2 text-14px bg-yellow rounded-t-sm border-yellow border-b-2 border-opacity-30">
                      {address.default
                        ? "Default Address"
                        : address.addressType === "Home"
                        ? "Home Address"
                        : "Office Address"}
                    </p>
                  )}
                  <div className="pl-6 pe-6 pt-4 min-h-32">
                    <h5 className="font-extrabold text-15px mb-2 break-words">
                      {address.name}
                    </h5>
                    <p className="text-14px break-words">{address.addressLine1}</p>
                    <p className="text-14px break-words">{address.addressLine2}</p>
                    <p className="text-14px break-words">{address.city}</p>
                    <p className="text-14px break-words">Phone number: {address.phone}</p>
                    <div className="mt-3 mb-2 flex space-x-1 text-13px text-yellow">
                      <button onClick={() => editAddress(address)}>Edit</button>
                      <span>|</span>
                      <button onClick={() => handleaddress(address.id)}>
                        Remove
                      </button>
                      {!address.default && (
                        <>
                          <span>|</span>
                          <button onClick={() => setAsDefault(address.id)}>
                            Set as Default
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="ml-5 text-sm text-yellow font-semibold mb-5 mt-5 md:mb-2">
              No addresses found. Add a new address using the button above.
            </p>
          )}
          <div className="px-4">
            <Button
              className="block md:hidden text-center text-yellow w-full py-4 border border-black shadow-md font-semibold rounded-sm border-opacity-35 cursor-pointer mt-5"
              onClick={addNewAddress}
              text="Add Address"
            />
          </div>
          {isModalOpen && (
            <ConfirmModal
              className={selectedAddress.default && "animate-modal"}
              confirmOnClick={removeAddress}
              cancelOnClick={closeModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Address;
