import React, { useState, createContext } from "react";

const ProductContextStates = createContext();

const ProductContext = (props) => {
    const [getCategories, setCategories] = useState(null);
    const [selectedId, setSelectedIdData] = useState(0);
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);
    const [selectedProductDetails, setselectedProductDetails] = useState(0);
    const [cartList, setCartList] = useState([]);
    const [filterProducts, setFilteredProducts] = useState([]); // Corrected here
    const [orderSummaryData, setOrderSummaryData] = useState([]);
    const [productCategoryFilter, setProductCategoriesFilter] = useState();
    const [priceRangeMax, setPriceRangeMax] = useState([]);
    const [priceRangeMin, setPriceRangeMin] = useState([]);

    const getAllCategoriesData = (categoriesData) => {
        setCategories(categoriesData);
    };

    const categorySelectedIdData = (selectId) => {
        setSelectedIdData(selectId);
    };

    const getAllProductCategories = (selectedData) => {
        setSelectedSubCategories(selectedData);
    };

    const getProductCategoriesDataFilter = (id) => {
        setProductCategoriesFilter(id);
    };

    const selectProductDataId = (id) => {
        setselectedProductDetails(id);
    };

    const updateCartList = (updatedCart) => {
        setCartList(updatedCart);
    };

    // FAVOURITE CONTEXT

    const getFilteredProducts = (filteredData) => { // Updated function name
        setFilteredProducts(filteredData);
    };

    // Order summary

    const OrderSummary = (orderData) => {
        setOrderSummaryData(orderData);
    };
    // price summary
    const getMaxPrice = (priceDataMax) => {
        setPriceRangeMax(priceDataMax)
    }
    const getMinprice = (priceDataMin) => {
        setPriceRangeMin(priceDataMin)
    }

    return (
        <ProductContextStates.Provider
            value={{
                getAllCategoriesData,
                getCategories,
                selectedId,
                categorySelectedIdData,
                getAllProductCategories,
                selectedSubCategories,
                selectProductDataId,
                selectedProductDetails,
                updateCartList,
                cartList,
                getFilteredProducts, // Updated here
                filterProducts,
                orderSummaryData,
                OrderSummary,
                getProductCategoriesDataFilter,
                productCategoryFilter,
                getMaxPrice,
                priceRangeMax,
                getMinprice,
                priceRangeMin
            }}
        >
            {props.children}
        </ProductContextStates.Provider>
    );
};

export { ProductContextStates, ProductContext };
