import { AxiosConfig } from "./AxiosConfig";

export const getOrderItems = async (bodyData) => {
    return await AxiosConfig.post(`/orders/getAllOrders?requestId=${bodyData.requestId}`)
}

//place order

export const placeOrder = async (bodyData) => {
    return await AxiosConfig.post('/orders/placeOrder', bodyData)
}
