import { useContext, useEffect, useState } from "react";
import { Auth } from "../../Context/AuthContext";
import Loader from "../../acscomponents/Loader";
import { useNavigate } from "react-router-dom";
import {
  addAddress,
  addressTypes,
  getStateAndCity,
} from "../../Networking/AddressApisActions";
import Tick from "../../assets/svg/tick.svg";
import Breadcrumb from "../../pages/product/Breadcrumb";
import ToastMessage from "../../acscomponents/ToastMessage";
import { ToastMsgContext } from "../../Context/ToastMessageContext";

const NewAddress = () => {
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [fullName, setFullName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [landmark, setLandmark] = useState("");
  const [phone, setPhone] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [errors, setErrors] = useState("");
  const [selectedAddressType, setSelectedAddressType] = useState(null);
  const [filteredCities, setFilteredCities] = useState([]);
  const [hideCity, setHideCity] = useState(false);
  const [addressTypesList, setAddressTypesList] = useState([]);
  const [places, setPlaces] = useState([]);

  const userId = useContext(Auth);
  const navigate = useNavigate();
  const ToastMsgState = useContext(ToastMsgContext);

  useEffect(() => {
    // Fetch address types and state/city data
    const fetchData = async () => {
      try {
        const addressTypesResponse = await addressTypes();
        const stateAndCityResponse = await getStateAndCity();

        if (addressTypesResponse.data.code === 200) {
          setAddressTypesList(addressTypesResponse.data.data);
        }

        if (stateAndCityResponse.data.code === 200) {
          setPlaces(stateAndCityResponse.data.data);
          setFilteredCities(stateAndCityResponse.data.data[0].listCities)
        }
      } catch (error) {
        console.error(
          "Error fetching address types or state/city data:",
          error
        );
      }
    };

    fetchData();
  }, []);

  // console.log(, 'places');

  const handleRadioChange = (e) => {
    setSelectedAddressType(e.target.value);
  };

  const handleSubmit = () => {
    if (
      fullName.length === 0 ||
      addressLine1.length === 0 ||
      addressLine2.length === 0 ||
      landmark.length === 0 ||
      phone.length === 0 ||
      pincode.length === 0 ||
      selectedAddressType.length === 0 ||
      state.length === 0 ||
      city.length === 0
    ) {
      return setErrors("Please Fill out these fields");
    }

    setLoading(true);
    const bodyData = {
      userId: userId.customerId,
      name: fullName,
      streetName: addressLine1,
      locality: addressLine2,
      city: city,
      state: state,
      pincode: pincode,
      landmark: landmark,
      addressType: selectedAddressType,
      phone: phone,
      default: isChecked,
    };

    addAddress(bodyData).then((res) => {
      if (res.data.code === 200) {
        setLoading(false);
        setErrors("");
        ToastMsgState.getMessage("Address added successfully");
        ToastMsgState.getCode(200);
        ToastMsgState.getIsActive(true);
        navigate(-1);
      }
      if (res.data.code === 204) {
        setErrors(res.data.message);
        setTimeout(() => setErrors(""), 3000);
        setLoading(false);
        ToastMsgState.getMessage("Kindly Login");
        ToastMsgState.getCode(201);
        ToastMsgState.getIsActive(true);
      }
    });
  };


  return (
    <div className="h-[90vh] overflow-scroll no-scrollbar bg-white border border-gray-200 my-5 border-opacity-5 shadow-md mx-10 font-Inter px-4 lg:px-48 py-2 pb-12">
      {loading && <Loader />}
      <ToastMessage />
      <div className="flex">
        <Breadcrumb />
      </div>
      <div className="border-2 px-4 lg:px-16 py-2">
        <div className="md:flex items-center justify-between">
          <p className="text-17px text-yellow font-semibold mt-3">
            Address Information
          </p>
          <div className="flex gap-x-5 items-center md:justify-center">
            {addressTypesList.map((item) => (
              <div key={item.id} className="flex items-center">
                <input
                  type="radio"
                  value={item.id}
                  onChange={handleRadioChange}
                  name="addressType"
                  className="accent-yellow scale-75"
                />
                <label className="ms-1.5 font-Inter font-semibold uppercase text-xs">
                  {item.name}
                </label>
              </div>
            ))}
            {errors && !isChecked && (
              <p className="text-xs text-red font-Inter mt-1.5">{errors}</p>
            )}
          </div>
        </div>
        <div className="mt-5 grid grid-cols-1 gap-x-14 gap-y-2 text-xs ">
          <div>
            <label className="block font-Inter font-medium ">
              Full Name (First and Last name)
            </label>
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              className="w-full border pl-5 pe-5 appearance-none font-normal text-xs border-yellow py-2 rounded-md border-opacity-50 mt-1 focus:outline-none"
            />
            {errors && !fullName && (
              <p className="text-xs text-red">{errors}</p>
            )}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-3 text-xs">
            <div>
              <label className="block font-semibold">Country / Region</label>
              <input
                type="text"
                value={places[0]?.country || ""}
                disabled
                className="w-full border pl-5 pe-5 appearance-none font-normal text-xs border-yellow py-2 rounded-md border-opacity-50 mt-1 focus:outline-none"
              />
            </div>
            <div>
              <label className="block font-Inter font-medium">
                Flat, House No.
              </label>
              <input
                type="text"
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
                className="w-full border pl-5 pe-5 appearance-none font-normal text-xs border-yellow py-2 rounded-md border-opacity-50 mt-1 focus:outline-none"
              />
              {errors && !addressLine1 && (
                <p className="text-xs text-red">{errors}</p>
              )}
            </div>
          </div>
          <div>
            <label className="block font-Inter font-medium">
              Area, Street, Sector, Village
            </label>
            <input
              type="text"
              value={addressLine2}
              onChange={(e) => setAddressLine2(e.target.value)}
              className="w-full border pl-5 pe-5 appearance-none font-normal text-xs border-yellow py-2 rounded-md border-opacity-50 mt-1 focus:outline-none"
            />
            {errors && !addressLine2 && (
              <p className="text-xs text-red">{errors}</p>
            )}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-3 text-xs">
            <div>
              <label className="block font-Inter font-medium">Landmark</label>
              <input
                type="text"
                value={landmark}
                onChange={(e) => setLandmark(e.target.value)}
                placeholder="Eg. Near Apollo Hospital"
                className="w-full border pl-5 pe-5 appearance-none font-normal text-xs border-yellow py-2 rounded-md border-opacity-50 mt-1 focus:outline-none"
              />
              {errors && !landmark && (
                <p className="text-xs text-red">{errors}</p>
              )}
            </div>
            <div>
              <label className="block font-Inter font-medium">
                Mobile Number
              </label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full border pl-5 pe-5 appearance-none font-normal text-xs border-yellow py-2 rounded-md border-opacity-50 mt-1 focus:outline-none"
              />
              {errors && !phone && <p className="text-xs text-red">{errors}</p>}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-3 text-xs">
            <div>
              <label className="block font-Inter font-medium">Pincode</label>
              <input
                type="text"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                className="w-full border pl-5 pe-5 appearance-none font-normal text-xs border-yellow py-2 rounded-md border-opacity-50 mt-1 focus:outline-none"
              />
              {errors && !pincode && (
                <p className="text-xs text-red">{errors}</p>
              )}
            </div>
            <div>
              <label className="block font-Inter font-medium">State</label>
              <select
                value={state}
                onChange={(e) => setState(e.target.value)}
                className="w-full border pl-5 pe-5 appearance-none font-normal text-xs border-yellow py-2 rounded-md border-opacity-50 mt-1 focus:outline-none"
              >
                <option value="">Select State</option>
                {places && places.map((place) => (
                  <option key={place.id} value={place.id}>
                    {place.name}
                  </option>
                ))}
              </select>
              {errors && !state && <p className="text-xs text-red">{errors}</p>}
            </div>
          </div>
          <div>
            <label className="block font-Inter font-medium">City</label>
            <select
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="w-full border pl-5 pe-5 appearance-none font-normal text-xs border-yellow py-2 rounded-md border-opacity-50 mt-1 focus:outline-none"
            >
              <option value="">Select City</option>
              {filteredCities.map((cityItem) => (
                <option key={cityItem.id} value={cityItem.id}>
                  {cityItem.name}
                </option>
              ))}
            </select>
            {errors && !city && <p className="text-xs text-red">{errors}</p>}
          </div>
        </div>
        <div className="flex justify-between">
          <button
            type="button"
            onClick={handleSubmit}
            className="bg-yellow py-2 px-4 text-white font-semibold rounded-md mt-5"
          >
            Save Address
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewAddress;
