import React, { useState } from "react";

const OrderTrackingH = ({stage}) => {
  const [status, setStatus] = useState("Order Process");
  const [index, setIndex] = useState(0);
  const [steps, setSteps] = useState(stage)

 

  const handleStatusChange = (newStatus, newIndex) => {
    setStatus(newStatus);
    setIndex(newIndex);
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "75px",
    fontFamily: 'Inter',
  };

  const stepContainerStyle = {
    width: "200px",
    height: "70px",
    border: '1.5px solid #91bcd9',
    display: "flex",
    flexDirection: 'column',
    position: "relative",
    right: "75px",
    borderRadius: "5px",
  };

  const stepTextStyle = {
    paddingLeft: "15px",
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px",
    fontSize: "15px",
    opacity: "0.8",
  };

  const stepDateStyle = {
    paddingLeft: "15px",
    display: "flex",
    justifyContent: "center",
    color: "gray",
    paddingTop: "5px",
  };

  // const lineStyle = {
  //   width: "150px",
  //   height: "3px",
  //   backgroundColor: "black",
  //   marginTop: "25px",
  //   marginLeft: "21px",
  // };

  const renderSVG = (isSelected) => {
    if (isSelected) {
      return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="25" cy="25" r="25" fill="black" />
          <g clipPath="url(#clip0_1444_25252)">
            <path d="M22.968 29.099L33.3387 15.4261L35.9779 17.6873L23.0058 34.3638L14.0391 25.3971L16.2488 23.1874L22.2162 29.1506L22.6217 29.5557L22.968 29.099Z" fill="white" stroke="white" />
          </g>
          <defs>
            <clipPath id="clip0_1444_25252">
              <rect width="23.3333" height="23.3333" fill="white" transform="translate(13.332 13.334)" />
            </clipPath>
          </defs>
        </svg>
      );
    }
    return (
      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="25" cy="25" r="25" fill="#E2E4E7" />
        <g clipPath="url(#clip0_1444_25260)" >
          <rect width="23.3333" height="23.3333" transform="translate(13.332 13.334)" fill="#E2E4E7" />
          <path d="M22.968 29.099L33.3387 15.4261L35.9779 17.6873L23.0058 34.3638L14.0391 25.3971L16.2488 23.1874L22.2162 29.1506L22.6217 29.5557L22.968 29.099Z" fill="#787A7D" stroke="#E2E4E7" />
        </g>
        <defs>
          <clipPath id="clip0_1444_25260">
            <rect width="23.3333" height="23.3333" fill="white" transform="translate(13.332 13.334)" />
          </clipPath>
        </defs>
      </svg>
    );
  };

  return (
    <>
    <div style={containerStyle} className="flex  flex-col lg:flex-row">
      {steps && steps.map((itm, i) => (
        <div key={i} className="flex lg:block">
          <div  className="flex  flex-col lg:flex-row">
            <div >
            <button
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
              {renderSVG(index >= i)}
            </button>
            </div>
            
            <div className="flex-col  ml-[4.5rem] mr-[1rem] md:ml-0">
            {i < steps.length - 1 && <div style={{}} className=" w-[3px] h-32  lg:w-[150px] lg:h-[3px] mt-[25px] ml-[21px] bg-black"></div>}
            </div>
            
          </div>
          <div style={stepContainerStyle} className="ml-24 lg:ml-0">
            <p style={stepTextStyle}>{itm.stage}</p>
            <p style={stepDateStyle}>{itm.date}</p>
          </div>
        </div>
      ))}
    </div>
    </>
  );
};

export default OrderTrackingH;