import React, { useState } from "react";
import { leftArrow, threedotsvertical } from "../assets/svg/index";

const Header = () => {
  
  const [hamBurgerStatus, setHamBurgerStatus] = useState(false);
  const handleHamBurgerMenu = () => {
    setHamBurgerStatus((prev) => !prev);
  };
  return (
    <div>
      <header className="font-jaldi bg-white shadow-md fixed left-0 right-0 z-30 ">
        <div className="flex justify-between md:px-8 px-4 items-center py-1.5 ">
          <h3 className="text-23px font-bold text-dark-brown flex-1 cursor-pointer"></h3>
          <p className="text-black-opacity-70 flex-1 text-center whitespace-nowrap hidden md:block ">
            Need help:{" "}
            <span className="text-15px font-bold text-dark-brown cursor-pointer">
              73739 32235 / 94880 68189
            </span>
          </p>
          <div className="flex flex-1 justify-end gap-x-5 lg:gap-x-10 ">
            <p className="text-18px hidden md:block cursor-pointer ">Contact</p>
            <p className="text-18px whitespace-nowrap hidden md:block cursor-pointer ">
              Order tracking
            </p>
            <img
              src={threedotsvertical}
              alt="threedotsvertical"
              className="md:hidden"
              onClick={() => {
                handleHamBurgerMenu();
              }}
            />
          </div>
        </div>
        {/* hamburgermenu */}
        <div
          className={`absolute right-0 z-50 bg-white p-3 top-0 ${
            hamBurgerStatus ? "block" : "hidden"
          } md:hidden `}
        >
          <div className="flex justify-end pr-1 py-1">
            <img
              src={leftArrow}
              alt=""
              onClick={() => {
                handleHamBurgerMenu();
              }}
            />
          </div>
          <p className="text-black-opacity-70 flex-1 text-center mt-2 py-1">
            Need help:{" "}
            <span className="text-15px font-bold text-dark-brown">
              73739 32235 / 94880 68189
            </span>
          </p>
          <p className="text-15px py-1">Contact</p>
          <p className="text-15px whitespace-nowrap py-1">Order tracking</p>
        </div>
      </header>
    </div>
  );
};

export default Header;
