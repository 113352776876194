import React, { useState } from 'react';
import MAINIMAGE from '../../assets/MAINIMAGE.png';
import { Email, Eye } from "../../assets/svg/index";

const PasswordReset = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true); // State to track if passwords match

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle the password reset logic here
    if (newPassword === confirmPassword) {
      alert('Password reset successfully!');
    } else {
      setPasswordsMatch(false); // Set state to indicate passwords don't match
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100 items-center justify-center" style={{ backgroundImage: `url(${MAINIMAGE})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="flex flex-col items-center bg-white p-8 rounded-lg shadow-lg w-full max-w-md mx-auto mt-12">
        <h2 className="text-25 font-semibold text-dark-brown font-Inter mb-4">Reset Your Password</h2>
        <form onSubmit={handleSubmit} className="w-full">
          <div className="mb-4 relative">
            <label htmlFor="newPassword" className="block text-gray-700 font-inter text-16 mb-2">New Password</label>
            <input
              type={showNewPassword ? 'text' : 'password'}
              id="newPassword"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                setPasswordsMatch(true); // Reset match state on input change
              }}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-brown-600 text-sm"
              placeholder="Enter New Password"
              required // Ensure field is required
            />
            <button
              type="button"
              onClick={() => setShowNewPassword(!showNewPassword)}
              className="absolute inset-y-0 right-3 flex items-center text-gray-600"
            >
              <img src={Eye} alt="Eye icon" className="h-5 w-5 mt-8 text-gray-400" />
            </button>
          </div>

          <div className="mb-6 relative">
            <label htmlFor="confirmPassword" className="block text-gray-700 font-inter text-16 mb-2">Confirm Password</label>
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setPasswordsMatch(true); // Reset match state on input change
              }}
              className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-brown-600 text-sm ${!passwordsMatch ? 'border-red-500' : ''}`} // Apply red border if passwords don't match
              placeholder="Enter Confirm"
              required // Ensure field is required
            />

            <button
              type="button"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="absolute inset-y-0 right-3 flex items-center text-gray-600"
            >
              <img src={Eye} alt="Eye icon" className="h-5 w-5 mt-8 text-gray-400" />
            </button>
            {!passwordsMatch && (
              <p className="text-red text-xs mt-1">Passwords do not match</p> 
            )}
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="w-50 bg-dark-brown text-white py-2 items-center justify-center px-8 rounded-2xl font-Inter text-18 hover:bg-brown-700"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordReset;
