import React, { useState } from "react";
import { Link } from "react-router-dom";

const Footerpage = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.trim() === '') {
      setError("Please enter your E-Mail");
    } else if (!emailRegex.test(email)) {
      setError("Please enter a valid email address");
    } else {
      setError("")
    }
  }
  
  return (
    <div className='w-full px-8 py-10 bg-white'>
      <div className='grid grid-cols-1 md:grid-cols-5 gap-3'>

        <div className='col-span-2 bg-dark-brown text-white px-4 py-2 md:px-8 md:py-4 md:order-5'>
          <h1 className='font-Inter font-bold text-lg md:text-3xl tracking-wider mb-5'>Exclusive offers straight to your inbox</h1>
          <p className='text-xs md:text-sm font-Inter font-medium text-pretty leading-relaxed mb-5'>Join to get special offers, free giveaways, and once-in-a-lifetime deals.</p>

          <div className='flex items-center'>
            <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className='appearance-none font-Inter bg-transparent border-b w-full px-1.5 py-3 focus:outline-none' placeholder="your-email@example.com"/>
            <button onClick={handleSubmit} className='bg-transparent px-1.5 py-3 font-Inter text-xs'>SUBMIT</button>
          </div>
          {error && <p className='text-xs italic'>{error}</p>}
        </div>

        <div className='flex flex-col space-y-3'>
          <h1 className='cursor-pointer font-Inter font-black text-lg tracking-wider'>SHOP</h1>
          {shop.map((item, i) => (
            <Link key={i} to={item.link} className='relative text-sm font-Inter font-semibold before:absolute before:-bottom-1 before:left-0 before:right-0 before:h-0.5 before:bg-black before:scale-x-0 before:origin-left before:transition-transform before:duration-300 hover:before:scale-x-50'>{item.title}</Link>
          ))}
        </div>

        <div className='flex flex-col space-y-3'>
          <h1 className='cursor-pointer font-Inter font-black text-lg tracking-wider'>NAVIGATE</h1>
          {navigate.map((item, i) => (
            <Link key={i} to={item.link} className='relative text-sm font-Inter font-semibold before:absolute before:-bottom-1 before:left-0 before:right-0 before:h-0.5 before:bg-black before:scale-x-0 before:origin-left before:transition-transform before:duration-300 hover:before:scale-x-50'>{item.title}</Link>
          ))}
        </div>

        <div className='flex flex-col space-y-3 px-0 md:px-2'>
          <h1 className='cursor-pointer font-Inter font-black text-lg tracking-wider'>SOCIAL</h1>
          {social.map((item, i) => (
            <a key={i} href={item.link} target="_blank" className='relative text-sm font-Inter font-semibold before:absolute before:-bottom-1 before:left-0 before:right-0 before:h-0.5 before:bg-black before:scale-x-0 before:origin-left before:transition-transform before:duration-300 hover:before:scale-x-50'>{item.title}</a>
          ))}
        </div>

      </div>
      <hr className='mt-2' />
      <div className='mt-5 text-end'>
        <p className='font-Inter text-nowrap tracking-wide text-[10px] md:text-14px  md:text-base'><span>&#169;</span> LUXORÉ DELIGHTS 2024  POWERED BY <a href="https://eloiacs.com/" target="_blank" className='relative before:absolute before:-bottom-1 before:left-0 before:right-0 before:h-0.5 before:bg-black before:scale-x-0 before:origin-left before:transition-transform before:duration-300 hover:before:scale-x-100'>ELOIACS</a></p>
      </div>
    </div>
  );
};

export default Footerpage;


const shop = [
  { title: "Home", link: "/" },
  { title: "Products", link: "/" },
  { title: "Home", link: "/" },
  { title: "About", link: "/about-us" },
];

const navigate = [
  { title: "FAQ", link: "/FAQ" },
  { title: "Terms of Service", link: "/" },
];

const social = [
  { title: "Instagram", link: "https://www.instagram.com/" },
  { title: "Facebook", link: "https://www.facebook.com/" },
  { title: "Twitter", link: "https://x.com/" },
];