import React, { useContext, useEffect, useState } from 'react';
import { ReviewContextState } from "../../Context/ReviewContext";
import { getReviewApi } from "../../Networking/ReviewApiAction";
import { Auth } from "../../Context/AuthContext";

const ProductReview = (props) => {
  const ReviewStates = useContext(ReviewContextState);
  const AuthUserContextStates = useContext(Auth);
  const [visibleReviews, setVisibleReviews] = useState(5); 

  useEffect(() => {
    
    const bodyData = {
      userId: AuthUserContextStates.customerId,
      requestedId: props.id,
    };
    getReviewApi(bodyData)
      .then((response) => {
        if (response?.data?.data?.ratings) {
        ReviewStates.getAllReviewData(response.data.data.ratings);      }
        else{  ReviewStates.getAllReviewData([]);} 
      })     
  }, [props.id]);

  const handleViewMore = () => {
    setVisibleReviews((prev) => prev + 5);
  };

  return (
    <div className="">
      <div className="container mx-auto max-w-3xl bg-white lg:px-8 ">
        <div className="text-center text-25px font-quicksand font-semibold text-amber-900 mb-6">
          Customer Reviews </div>
        <div className={` ${ReviewStates.getReview.length > 5 ? 'h-[600px]' : 'min-h-16'} overflow-y-auto horizontal-scroll`}>
          {ReviewStates.getReview && ReviewStates.getReview.length > 0 ? (
            ReviewStates.getReview.slice(0, visibleReviews).map((item) => (
              <div key={item.id} className="flex flex-col px-4 py-2 mb-4 border-b bg-gray-50 border-gray-200 rounded-md shadow-sm hover:shadow-md transition-shadow duration-200">
                <div className="flex items-center gap-3 py-3">
                  <div className="flex items-center   text-black text-sm rounded-md ">
                    <span className="mr-2 font-semibold font-jaldi text-dark-brown hover:text-black text-pretty text-justify text-20px ">{item.username}</span>
                    {Array.from({ length: item.rating }).map((_, index) => (
                      <svg
                        key={index}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#78350f"
                        viewBox="0 0 24 24"
                        width="16"
                        height="16"
                        className="mr-1 "
                      >
                        <path d="M12 17.27L18.18 21 16.54 14.36 22 9.27H14.81L12 2 9.19 9.27H2L7.46 14.36 5.82 21z" />
                      </svg>
                    ))}
                  </div>

                  <p className="font-bold text-sm text-gray-700">{item.score ? item.score : null}</p>
                </div>
                <p className="mt-2 text-sm text-gray-600 italic">{item.comments ? item.comments : null}</p>
                <div className="flex items-center mt-3 text-gray-500 text-xs">
                  <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM9.42933 14.28L15.1867 7.08267L14.1467 6.25067L9.23733 12.3853L5.76 9.488L4.90667 10.512L9.42933 14.28Z" fill="black" />
                  </svg>
                  <p className="ml-2">Certified Buyer, 29 April, 2023</p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500">No reviews available</p>
          )}
        </div>
        {ReviewStates.getReview && visibleReviews < ReviewStates.getReview.length && (
          <div className="text-center mt-4">
            <button
              onClick={handleViewMore}
              className="px-6 py-2 bg-amber-900 text-white rounded-full hover:bg-dark-brown font-QuattrocentoSans font-black transition duration-200"
            >
              View More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductReview;
