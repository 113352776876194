import { createContext, useState } from "react";

const ReviewContextState = createContext()

const ReviewContext = (props) => {
    const [getReview, setGetReview] = useState([])

    const getAllReviewData = (getData) => {
        setGetReview(getData)
    }
    return (
        <ReviewContextState.Provider value={{ getAllReviewData, getReview }}>
            {props.children}
        </ReviewContextState.Provider>
    )

}
export { ReviewContext, ReviewContextState }