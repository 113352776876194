import React, { useState, useEffect, useContext } from "react";
import "../App.css";
import { ProductContextStates } from "../Context/ProductContext";

const PriceSeeker = (props) => {
  const [isFocused, setIsFocused] = useState(false);
  const { min, max } = props;
  const [priceRange, setPriceRange] = useState([min, max]);

  const productContextStates = useContext(ProductContextStates);

  useEffect(() => {
    setPriceRange([min, max]);
  }, [min, max]);

  const handleMinChange = (e) => {
    const newMinValue = Math.min(Number(e.target.value), priceRange[1] - 1);
    setPriceRange([newMinValue, priceRange[1]]);
    productContextStates.getMinprice([newMinValue, priceRange[1]]);
  };

  const handleMaxChange = (e) => {
    const newMaxValue = Math.min(Number(e.target.value), max);
    const validMaxValue = Math.max(newMaxValue);
    setPriceRange([priceRange[0], validMaxValue]);
    productContextStates.getMaxPrice([priceRange[0], validMaxValue]);
  };

  return (
    <div>
      <div
        className="range-slider"
        style={{
          position: "relative",
          height: 10,
          width: "100%",
          backgroundColor: "#ddd",
          borderRadius: 5,
          marginTop: 10,
        }}
      >
        <input
          type="range"
          min={min}
          max={max}
          value={priceRange[0]}
          onChange={handleMinChange}
          className="thumb -top-1"
          style={{
            zIndex: priceRange[0] >= priceRange[1] * 0.8 ? 5 : 3,
            width: "100%",
            position: "absolute",
            pointerEvents: "auto",
            top: -3,
          }}
        />

        <input
          type="range"
          min={min}
          max={max}
          value={priceRange[1]}
          onChange={handleMaxChange}
          className="thumb"
          style={{
            width: "100%",
            position: "absolute",
            top: -3,
            background: "transparent",
            pointerEvents: "none",
            zIndex: priceRange[1] === priceRange[0] ? 6 : 4,
          }}
        />

        <div
          style={{
            left: `${(priceRange[0] / max) * 100}%`,
            width: `${((priceRange[1] - priceRange[0]) / max) * 100}%`,
            position: "absolute",
            height: "10px",
            backgroundColor: "#D48223",
            borderRadius: "5px",
            top: 0,
            zIndex: 1,
          }}
          className="slider-track"
        ></div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "15px",
          gap: "3px",
        }}
      >
        <div className="w-14 md:w-full">
          <input
            type="number"
            id="minPrice"
            value={
              priceRange[0] === 0 && !isFocused
                ? "0"
                : priceRange[0] === 0
                ? ""
                : priceRange[0]
            } // Handle focus and value display
            onChange={handleMinChange}
            onBlur={() => setIsFocused(false)} // When focus is lost, show "0" if value is 0
            onFocus={() => setIsFocused(true)} // On focus, allow empty input
            min={min}
            max={priceRange[1] - 1}
            className="md:block hidden"
            style={{
              width: 50,
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "0.75px",
            }}
          />
        </div>
        <div>
          <input
            type="number"
            id="maxPrice"
            value={priceRange[1] || ""}
            onChange={handleMaxChange}
            min={priceRange[0] + 1}
            max={max}
            className="md:block hidden w-16"
            style={{
              width: 50,
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "0.75px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceSeeker;
