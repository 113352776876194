import { AxiosConfig } from "./AxiosConfig"

export const AddReviewApi = async(bodyData) => {
    return await AxiosConfig.post('/ratings/addProductRating',bodyData)
}

export const getReviewApi = async (data) => {
    return AxiosConfig.post(`/ratings/getRatings?requestId=${data.requestedId}&userId=${data.userId}`);
}

