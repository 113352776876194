import React, { useContext, useEffect, useState } from "react";
import el1 from "../assets/el-1.png";
import el2 from "../assets/el-2.png";
import el3 from "../assets/el-3.png";
import el4 from "../assets/el-4.png";
import el5 from "../assets/el-5.png";
import off2 from "../assets/off2.png";
import off3 from "../assets/off3.png";
import lbimg from "../assets/lbimg.png";
import kiwi from "../assets/kiwi.png";
import {
  caliocn,
  ratingstar,
  leftArrow,
  rightArrow,
} from "../assets/svg/index";
import CategoryComponent from "./CategoryComponent";
import Loader from "../acscomponents/Loader";
import { getTopSellingProducts } from "../Networking/ProductApisAction";
import { useNavigate } from "react-router-dom";
import Carousel from "../acscomponents/Carousel";
import ImageWithLoader from "../acscomponents/ImageLoaderComponent";
import { getAllOffers } from "../Networking/OfferActions";
import { OfferState } from "../Context/OffersContext";

const Dashboard = () => {
  const [bestProducts, setBestProducts] = useState([
    {
      id: 1,
      img: el1,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 2,
      img: el2,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 3,
      img: el3,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 4,
      img: el4,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 5,
      img: el5,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 6,
      img: el5,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 7,
      img: el5,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 8,
      img: el5,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 9,
      img: el5,
      name: "Best Of Shavers",
      price: 1000,
    },
    {
      id: 10,
      img: el5,
      name: "Best Of Shavers",
      price: 1000,
    },
  ]);
  const [offerProducts, setOfferProducts] = useState([
    {
      id: 1,
      img: off2,
    },
    {
      id: 2,
      img: off3,
    },
    {
      id: 3,
      img: off2,
    },
  ]);
  const [topSelingBanner, setTopSelingBaner] = useState([
    { id: 1, img: lbimg },
  ]);
  const [topSelingProducts, setTopSellingProducts] = useState([]);

  const [topSelTreProRecAdd, setTopSelTreProAdd] = useState([
    {
      id: 1,
      name: "Top Selling",
      products: [
        {
          id: 1,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: true,
        },
        {
          id: 2,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: false,
        },
        {
          id: 3,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: false,
        },
      ],
    },
    {
      id: 2,
      name: "Trending Products",
      products: [
        {
          id: 1,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: false,
        },
        {
          id: 2,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: true,
        },
        {
          id: 3,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: false,
        },
      ],
    },
    {
      id: 3,
      name: "Recently Added",
      products: [
        {
          id: 1,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: false,
        },
        {
          id: 21,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: false,
        },
        {
          id: 3,
          img: kiwi,
          prodName: "Dried kiwi",
          rate: 200,
          rating: 5,
          cartStatus: true,
        },
      ],
    },
  ]);

  const [iconBtnShowStatus, setIocnBtnShowStatus] = useState(null);
  const [iconStatus, setIconStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const offerContext = useContext(OfferState);

  const handleIconBtnShowStatus = (id) => {
    setIocnBtnShowStatus(id);
  };
  const handleHideIconBtn = () => {
    setIocnBtnShowStatus(null);
  };
  const handleIconStatus = (id) => {
    setIconStatus(id);
  };
  const handleHideIcon = () => {
    setIconStatus(null);
  };

  useEffect(() => {
    setLoading(true);
    getTopSellingProducts()
      .then((response) => {
        const topSellingData = response.data.data.topSelling.slice(0, 9);
        if (topSellingData.length !== 0) {
          setTopSellingProducts(topSellingData);
        } else {
          setTopSellingProducts({});
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setTopSellingProducts(null);
      });
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    getAllOffers().then(res => {
      if (res.data.code === 200) {
        offerContext.updateOfferList(res.data.data);
        setLoading(false);
      } else if (res.data.code === 204) {
        setLoading(false);
      }
    }).catch(err => {
      console.error(err);
      setLoading(false);
    })
  }, [])

  // const navigate = useNavigate();
  // const handleProductDetails = (id) => {
  //   navigate("ProductDetails", { state: { selectedProductId: id } });
  // };

  const handleProductDetails = (selectedProductId) => {
    const url = `/ProductDetails?selectedProductId=${selectedProductId}`;
    window.open(url, '_blank');
  };

  // const handleProductDetails = (selectedProductId) => {
  //   navigate('/ProductDetails', { state: { selectedProductId: selectedProductId, categoryId: categoryId } });
  // };

  const toggleFavourite = (id) => {
    setIsFavourite((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <>
      {loading && <Loader className={loading ? "blur" : "blur-none"} />}
      <div className="font-quicksand bg-lightgrey ">
        {/* best of electronics */}
        <div className="md:px-8 px-4  bg-white md:pb-16 ">
          {/* first section */}
          {/* Category component */}
          <CategoryComponent />
          <div className="">
            <div className="h-96 w-full py-3">
              <Carousel images={offerContext.offerList} />
            </div>
            <h3 className="text-dark-brown font-bold text-18px pt-10">
              Best Of Electronics
            </h3>
            <div className="md:px-6 sm:px-4">
            {/* <div className=""> */}
              <div className="flex mt-4 space-x-2 md:space-x-5 overflow-x-scroll horizontal-scroll">
                {bestProducts.map((itm) => (
                  <div
                    key={itm.id}
                    className="border border-brown-opacity-20 bg-white flex flex-col items-center justify-center px-6 py-3 rounded-md flex-shrink-0"
                  >
                    <div>
                      <img
                        src={itm.img}
                        alt="product img"
                        className="h-120px w-98px"
                      />
                    </div>
                    <p className="font-semibold text-16px mt-2">{itm.name}</p>
                    <p className="font-semibold text-13px">
                      From &#8377;{itm.price}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* second section */}
          <div>
            <div className="flex overflow-x-scroll gap-x-5 mt-6 md:mt-10 horizontal-scroll  no-scrollbar">
              {offerProducts.map((itm) => (
                <div key={itm.id} className="flex-shrink-0 w-full sm:w-auto">
                  <img
                    src={itm.img}
                    alt="offer product image"
                    className="sm:w-450px sm:h-276px rounded-md"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* beauty toys and gifts */}

        <div className="md:px-8 px-4 py-2 bg-white  pb-10">
          {/* first section */}
          <div className="overflow-hidden">
            <h3 className="text-dark-brown font-bold text-18px">
              beauty toys and gifts{" "}
            </h3>
            <div className="md:px-8 sm:px-4">
              <div className="flex mt-3 gap-x-5 overflow-x-scroll horizontal-scroll">
                {bestProducts.map((itm) => (
                  <div
                    key={itm.id}
                    className="border border-brown-opacity-20 bg-white flex flex-col items-center justify-center px-6 py-3 rounded-md flex-shrink-0"
                  >
                    <div>
                      <img
                        src={itm.img}
                        alt="product img"
                        className="h-120px w-98px"
                      />
                    </div>
                    <p className="font-semibold text-16px mt-2">{itm.name}</p>
                    <p className="font-semibold text-13px">
                      From &#8377;{itm.price}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* second section */}
          <div>
            <div className="flex overflow-x-scroll gap-x-5 mt-10 md:mt-16 horizontal-scroll no-scrollbar">
              {offerProducts.map((itm) => (
                <div key={itm.id} className="flex-shrink-0 w-full sm:w-auto">
                  <img src={itm.img} alt="offer product"   className="sm:w-450px sm:h-276px rounded-md"  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Top selling products */}
        <div className="md:pl-8 lg:pr-8 px-4  mt-7 md:pb-24 pb-10">
         
          {/* images container */}
          <div className="grid md:grid-cols-2 h-full overflow-hidden  p-1.5 md:space-x-4 ">
            {/* left side */}
            <div className="col-span-1 h-96 my-2 md:my-0 md:h-[650px] ">
            <h3 className="text-20px font-bold text-dark-brown  ml-3">
              Top Selling Products
            </h3>
              {topSelingBanner.map((itm) => (
                <div key={itm.id} className="flex justify-center">
                  <ImageWithLoader
                    src={itm.img}
                    alt="Top selling product banner"
                    imgClass="object-fill w-screen h-96 md:h-[650px]"
                  />
                </div>
              ))}
            </div>
            {/* right side */}
            <div className="">
            <div className="flex gap-x-2 sm:gap-x-4 md:gap-x-8 md:pr-7 lg:pr-20 flex-wrap gap-y-3 mb-5 justify-center">
              {/* <button className="font-jaldi text-16px">OFFER PRODUCTS</button>
              <button className="font-jaldi text-16px border border-black px-2 py-1">
                POPULAR PRODUCTS
              </button> */}
            </div>
            <div className="col-span-1  md:pt-2.5 items-start h-[650px] overflow-scroll no-scroll ">
              <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 lg:gap-5 pt-10 md:pt-1.5 ">
                {topSelingProducts ? (
                  topSelingProducts.map((itm) => (
                    <div key={itm.id} className="p-1 space-x-5">
                      <div className="bg-white rounded-md shadow-md overflow-hidden max-h-48  p-2 md:w-36 xl:w-48 2xl:w-96">
                        <div
                          className="flex justify-center items-center cursor-pointer h-28 md:3 "
                          onClick={() => handleProductDetails(itm.id)}
                        >
                          <ImageWithLoader
                            src={itm.productImage}
                            alt="product image"
                            imgClass="object-contain h-20 lg:h-24 md:w-full p-1 "
                          />
                        </div>
                        <div className="grid shadow-inner w-full h-16 border-b-2 p-2 text-center">
                          <p className="font-semibold text-10px  md:text-xs lg:text-md  text-pretty break-words">
                            {itm.productName}
                          </p>
                          <p className="font-semibold text-sm text-dark-brown text-nowrap">
                            Rs. {itm.discountedPrice} /-
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <p className="text-center text-dark-brown font-semibold font-quicksand text-16px">No products found</p>
                  </>
                )}
              </div>
              </div>
            </div>
          </div>
        </div>

        {/* three colomns */}
        {/* <div className="bg-white md:px-8 md:pt-16 pt-10 md:pb-20 pb-10 font-Inter">
          <div className="flex flex-wrap gap-y-10 ">
            {topSelTreProRecAdd.map((itmSection) => (
              <div
                key={itmSection.id}
                className="lg:flex-flex-1-30% md:flex-flex-1-50% flex-flex-1-100% px-4"
              >
                <div className="border-2 border-lightgrey rounded-md">
                  <div className="py-4 flex items-center justify-between pr-4">
                    <h3 className="pl-6 font-bold text-20px text-dark-brown">
                      {itmSection.name}
                    </h3>
                    <div className="flex gap-x-2">
                      <img
                        src={leftArrow}
                        alt="leftArrow"
                        className="cursor-pointer"
                      />
                      <img
                        src={rightArrow}
                        alt="rightArrow"
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                  <div>
                    {itmSection.products.map((product) => (
                      <div
                        key={product.id}
                        className="border-t flex items-center"
                      >
                        <div className="flex-1 flex justify-center">
                          <img
                            src={product.img}
                            alt="product image"
                            className="w-108px h-122px"
                          />
                        </div>
                        <div className="flex-1 py-3">
                          <h3 className="font-semibold text-20px">
                            {product.prodName}
                          </h3>
                          <h3 className="font-semibold text-18px text-orangered">
                            Rs.{product.rate}/-
                          </h3>
                          <div className="flex gap-x-1 mt-1">
                            {product.rating ? (
                              Array.from({
                                length: product.rating,
                              }).map((_, i) => (
                                <img
                                  src={ratingstar}
                                  alt="rating star"
                                  key={i}
                                />
                              ))
                            ) : (
                              <p>No rating available</p>
                            )}
                          </div>
                          <div
                            className={` ${product.cartStatus
                              ? "bg-gold-yellow"
                              : "bg-dark-brown"
                              } w-fit flex py-1 px-2 gap-x-2 mt-2 cursor-pointer`}
                          >
                            <p
                              className={`font-quicksand text-10px text-white `}
                            >
                              ADD TO CART
                            </p>
                            <img src={caliocn} alt="calender icon" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
         <div className="h-96 w-full py-3">
          <h3>Shopping at offers</h3>
              <Carousel images={offerContext.offerList} />
            </div>
      </div>
    </>
  );
};
export default Dashboard;
