import React, { useContext, useEffect, useState } from "react";
import ImageWithLoader from "../../acscomponents/ImageLoaderComponent";
import { CartStates } from "../../Context/CartContext";
import { getAddress } from "../../Networking/AddressApisActions";
import { Auth } from "../../Context/AuthContext";
import { deleteicon } from "../../assets/svg";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { applyCoupon } from "../../Networking/CouponApisAction";
import Loader from "../../acscomponents/Loader";
import { placeOrder } from "../../Networking/OrderApiAction";
import { ToastMsgContext } from "../../Context/ToastMessageContext";
import ToastMessage from "../../acscomponents/ToastMessage";

const ShoppingCart = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const ToastMsgState = useContext(ToastMsgContext);
  const [addressData, setAddressesData] = useState([]);
  const CartContextState = useContext(CartStates);
  const AuthUserContextState = useContext(Auth);
  const [loading, setLoading] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [couponStatus, setCouponStatus] = useState(null);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [totalAmountAfterDiscount, setTotalAmountAfterDiscount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [couponId, setCouponId] = useState("");
  const [totalPrices, setTotalPrices] = useState(location.state.totalPrice);
  const [quantities, setQuantities] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const { getCode, getMessage, getIsActive } = useContext(ToastMsgContext);

  useEffect(() => {
    setLoading(true);
    const data = CartContextState.cartItems;
    if (AuthUserContextState.customerId && data && data.length > 0) {
      setCartItems(data);
      setLoading(false);
    }
  }, [CartContextState.cartItems, AuthUserContextState.customerId]);

  useEffect(() => {
    setLoading(true);
    const data = CartContextState.cartItems;
    if (AuthUserContextState.customerId && data && data.length > 0) {
      setCartItems(data);
      setLoading(false);
    }
  }, [CartContextState.cartItems, AuthUserContextState.customerId]);
  useEffect(() => {
    setLoading(true);
    if (AuthUserContextState.customerId) {
      getAddress(AuthUserContextState.customerId)
        .then((res) => {
          const data = res.data.data;
          if (data.length > 0) {
            const defaultAddress = data.find((p) => p.default === true);
            if (defaultAddress) {
              setAddressesData([defaultAddress]);
            } else {
              setAddressesData([]);
            }
          }
        })
        .catch(() => {
          setAddressesData([]);
        });
    } else {
      setAddressesData([]);
    }
    setLoading(false);
  }, [AuthUserContextState.customerId]);

  const handleCoupon = () => {
    if (isCouponApplied) {
      setAppliedCoupon("");
      setDiscount(0);
      setDiscountedAmount(0);
      setTotalAmountAfterDiscount(totalPrice);
      setCouponStatus(null);
      setSuccessMessage("");
      setIsCouponApplied(false);
    } else {
      applyCoupon({
        couponCode: appliedCoupon,
        purchaseAmount: totalPrice,
      }).then((response) => {
        const data = response.data;

        if (data.code === 200) {
          setCouponId(data.data.code);
          const discountAmount = data.data.discount || 0;
          const discountedAmount = data.data.discountedAmount;
          const totalAmountAfterDiscount =
            data.data.totalAmountAfterDiscount || totalPrice;
          setDiscount(discountAmount);
          setTotalAmountAfterDiscount(totalAmountAfterDiscount);
          setCouponStatus({
            type: "valid",
            message: "Coupon applied successfully!",
          });
          setIsCouponApplied(true);

          setDiscountedAmount(discountedAmount);
          ToastMsgState.getIsActive(true);
          ToastMsgState.getMessage(data.message);
          ToastMsgState.getCode(data.code);
        } else {
          setCouponStatus({ type: "invalid", message: data.message });
          ToastMsgState.getIsActive(true);
          ToastMsgState.getMessage(data.message);
          ToastMsgState.getCode(data.code);
          console.log(data, "else conf");
        }
      });
    }
  };

  const totalPrice = cartItems.reduce(
    (total, item) =>
      total + (totalPrices[item.id] || item.discountedPrice) * item.quantity,
    0
  );
  const totalCount = cartItems.reduce(
    (count, item) => count + (quantities[item.id] || item.quantity),
    0
  );

  // Update total amount after discount
  useEffect(() => {
    if (!isCouponApplied) {
      setTotalAmountAfterDiscount(totalPrice - totalPrice * (discount / 100));
    }
  }, [totalPrice, discount, isCouponApplied]);

  // Calculate the saved amount
  const savedAmount = totalPrice - totalAmountAfterDiscount;

  const HandlePaymentMode = () => {
    if (addressData.length > 0) {
      const addressId = addressData[0].id;
      const bodyData = {
        orderId: "",
        userId: AuthUserContextState.customerId,
        couponId: couponId || 0,
        addressId: addressId, // Ensure addressId is used here
        paymentType: 1,
        orderItems: cartItems,
      };
      placeOrder(bodyData).then((response) => {
        getCode(response.data.code);
        getMessage(response.data.message);
        getIsActive(true);
        if (response.data.code === 200) {
          navigate("/OrderConfirmed");
        }
      });
    } else {
      ToastMsgState.getIsActive(true);
      ToastMsgState.getMessage("You haven't selected an address yet.");
      ToastMsgState.getCode(201);
    }
  };

  return (
    <div className="min-h-full">
      {loading && <Loader className={loading ? "blur" : "blur-none"} />}
      <ToastMessage />
      <div className="bg-white py-3 md:px-7 h-full ">
        <div className="flex flex-col md:grid grid-cols-1 px-7 lg:grid-cols-3 gap-6 ">
          <div className="col-span-2  hover:shadow-md px-1.5 h-full">
            <h2 className="text-white bg-dark-brown hover:bg-slate-100 hover:text-black text-center py-1 font-bold font-mono">
              Shopping Cart
            </h2>
            <div className=" h-96 overflow-y-scroll no-scrollbar">
              {cartItems.length > 0 ? (
                cartItems.map((item) => (
                  <div
                    key={item.id}
                    className="flex flex-col md:flex-row items-center justify-between border-b py-3 md:px-3 hover:bg-slate-50 hover:shadow-sm"
                  >
                    <div className="flex flex-1 items-center gap-4">
                      <ImageWithLoader
                        src={item.productImage}
                        alt={item.productName}
                        imgClass="min-w-24 w-full h-full md:w-36 md:h-20 md:object-contain rounded-md pl-4"
                        containerClass="w-full md:w-36 h-20"
                      />
                      <div className="md:flex-1">
                        <h3 className="text-lg text-nowrap break-words w-48 font-semibold text-dark-brown">
                          {item.productName.slice(0, 50)}
                        </h3>
                        <p className="text-sm text-gray-500">
                          <span
                            className="text-sm text-pretty font-normal font-QuattrocentoSans content-span"
                            dangerouslySetInnerHTML={{
                              __html: item.description.slice(0, 50),
                            }}
                          />
                        </p>
                        <p className="text-sm text-emerald-600 font-semibold">
                          In Stock
                        </p>
                      </div>
                    </div>
                    <div className="text-xs md:text-sm pt-10 flex flex-2 text-right gap-5 justify-center items-center px-3 tracking-widest">
                      <p className="capitalize ">
                        Size:
                        <span className="font-semibold pe-2">
                          {item.productSize}
                        </span>
                        Qty:
                        <span className="font-semibold">{item.quantity}</span>
                      </p>
                      <p className="md:text-md font-bold text-dark-brown">
                        Rs.
                        {(totalPrices[item.id] || item.discountedPrice) *
                          item.quantity}
                      </p>
                      {/* <img className="text-red font-bold h-3 w-4 cursor-pointer" src={deleteicon} alt="delete" onClick={() => handleRemoveItem(item.id)} /> */}
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center text-gray-500">
                  No items in the cart.
                </p>
              )}
            </div>
            <div className="grid border-b-2">
              <h2 className="text-white bg-dark-brown text-center py-1 font-bold font-mono">
                Delivery Info
              </h2>
              <div className="space-y-4 py-2 grid">
                {addressData.length > 0 ? (
                  addressData.map((address, index) => (
                    <div key={index} className="grid gap-4 py-2 leading-3 px-5">
                      <div className="flex justify-between">
                        <div className="flex gap-3">
                          <input
                            type="radio"
                            name="address"
                            className="form-radio text-dark-brown"
                            checked={index === 0}
                          />
                          <p className="font-bold text-dark-brown text-16px ">
                            <span className="text-black text-14px font-medium pe-1.5">
                              User Name:
                            </span>
                            {address.name}
                          </p>
                        </div>
                        <Link to="/Logout">
                          <p className="text-dark-brown font-jaldi hover:underline underline-offset-2 px-3 text-16px font-semibold cursor-pointer">
                            Logout
                          </p>
                        </Link>
                      </div>
                      <div className="text-gray-600 md:px-10 md:space-y-1 leading-4">
                        <p>
                          {address.addressLine1}, {address.addressLine2},
                          {address.landmark},
                        </p>
                        <p>
                          {address.city} - {address.state}, PinCode:
                          {address.pincode}
                        </p>
                        <p>Phone Number: {address.phone}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-center text-gray-500">
                    No default address available.
                  </p>
                )}
                <div className="flex justify-center flex-col md:flex-row items-center">
                  <Link to={"/Address"}>
                    <button className="border md:w-fit px-5 h-8 bg-dark-brown text-sm text-white font-light font-Inter">
                      Change Address
                    </button>
                  </Link>
                  <Link to={"/NewAddress"}>
                    <p className="font-inter font-bold pt-5 md:pt-5 text-15px text-dark-brown md:px-50px md:py-6 w-full cursor-pointer">
                      + Add a New Address
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* Summary Section */}
          <div className="flex-1 md:col-span-1  py-2.5 ">
            {/* <div className="px-5 bg-gray-100  rounded-xl"> */}
            <div className="md:col-span-1 bg-light-gray rounded-lg border-2  grid p-3.5 shadow-md ">
              <h2 className="text-lg font-bold text-dark-brown">
                Order Summary
              </h2>
              <div className="flex justify-between mt-3">
                <p className="font-normal">
                  Items <span className="font-semibold">({totalCount})</span>
                </p>
                <p className="font-semibold">Rs. {totalPrice}</p>
              </div>

              <div className="flex justify-between mt-2">
                <p className="font-normal">
                  Discount
                  <span className="font-semibold">
                    ({Number(discount).toFixed(0)}%)
                  </span>
                </p>
                <p className="font-semibold text-red-600">
                  Rs. {Number(discountedAmount).toFixed(2)}
                </p>
              </div>

              <div className="flex justify-between mt-2">
                <p className="font-normal">Total</p>
                <p className="font-semibold">
                  Rs. {Number(totalAmountAfterDiscount).toFixed(2)}
                </p>
              </div>

              {/* Coupon Section */}
              <div className="mt-5">
                <h3
                  className={`${
                    isCouponApplied ? "hidden" : "block"
                  } text-16px text-gray-500 text-center py-2 font-bold`}
                >
                  Coupon Code
                </h3>

                {!isCouponApplied ? (
                  <div className="flex justify-between">
                    <input
                      type="text"
                      className="border border-gray-300 rounded-s-md w-full px-2 outline-none"
                      placeholder="Enter coupon code"
                      value={appliedCoupon}
                      onChange={(e) => setAppliedCoupon(e.target.value)}
                    />
                    <button
                      className="bg-dark-brown text-white px-4 py-1 rounded-e-md"
                      onClick={handleCoupon}
                    >
                      Apply
                    </button>
                  </div>
                ) : (
                  <div className="grid text-14px  ">
                    <p className="flex justify-start items-center font-semibold text-nowrap text-emerald-800 text-green-600 uppercase">
                      <span className="text-black font-medium">Coupon :</span>
                      {appliedCoupon}
                      <img
                        className="place-content-end bg-red-600 text-black px-4 py-2 rounded-md text-end cursor-pointer"
                        onClick={handleCoupon}
                        src={deleteicon}
                      />
                    </p>
                    <p className="font-medium text-nowrap text-emerald-800 text-green-600 capitalize">
                      You Save Upto Rs.
                      <span className="font-bold text-14px">{savedAmount}</span>
                      from your order
                    </p>
                  </div>
                )}
              </div>
              <div className="py-3 cursor-not-allowed">
                <p className="text-lg font-bold text-dark-brown">
                  Payment option
                </p>
                <div className="pt-4 flex justify-start items-center ">
                  <input
                    className="w-4 h-4 p-0.5 outline-offset-2  accent-dark-brown "
                    type="checkbox"
                    checked
                  />
                  <label className="font-Inter font-semibold pl-3 text-dark-brown ">
                    Cash On Delivery
                  </label>
                </div>
              </div>

              {/* Checkout Button */}
              <button
                className="bg-dark-brown text-white w-full py-2 rounded-md mt-6 hover:bg-white hover:text-dark-brown hover:border hover:border-dark-brown transition duration-300 text-18px font-bold tracking-wider"
                onClick={HandlePaymentMode}
              >
                Pay
                <span className="text-16px font-black">
                  {Number(totalAmountAfterDiscount).toFixed(2)}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCart;
