import { createContext, useState } from "react";

const OfferState = createContext();

const OfferContext = (props) => {
    const [offerList, setOfferList] = useState([]);

    const updateOfferList = (offers) => {
        setOfferList(offers);
    };

    return <OfferState.Provider value={{ offerList, updateOfferList }}>
        {props.children}
    </OfferState.Provider>
};

export { OfferState, OfferContext };