import React, { useContext, useEffect, useState } from "react";
import { leftArrow, rightArrow } from "../assets/svg";
import { ProductContextStates } from '../Context/ProductContext';
import { useNavigate } from "react-router-dom";

const Carousel = ({ images }) => {
  const [viewimageIndex, setViewImageIndex] = useState(0);
  const [btnHoverStatus, setBtnHoverStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const productContextStates = useContext(ProductContextStates);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentImage =
        viewimageIndex === images.length - 1 ? 0 : viewimageIndex + 1;
      setViewImageIndex(currentImage);
    }, 3000);
    return () => clearInterval(interval);
  }, [viewimageIndex, images.length]);

  const handleLeftClick = () => {
    const currentImage =
      viewimageIndex === 0 ? images.length - 1 : viewimageIndex - 1;
    setViewImageIndex(currentImage);
  };

  const handleRightClick = () => {
    const currentImage =
      viewimageIndex === images.length - 1 ? 0 : viewimageIndex + 1;
    setViewImageIndex(currentImage);
  };

  const handleDotClick = (i) => {
    setViewImageIndex(i);
  };

  const handleSelectedCategoryId = (selectedCategoryId) => {
    setLoading(true);
    productContextStates.categorySelectedIdData(selectedCategoryId);
    navigate("/ProductList", {
      state: { selectedCategoryId: selectedCategoryId },
    });
    console.log(selectedCategoryId,"selectedCategoryId");
    
  };

  return (
    <div className="relative w-full h-full">
      <div className="w-full h-full flex overflow-hidden">
        {images.map((img, i) => (
          <div
            key={i}
            className="w-full h-full flex-shrink-0 transition-transform ease-in-out duration-1000"
            style={{
              transform: `translateX(${-100 * viewimageIndex}%)`,
            }}
            onClick={() => handleSelectedCategoryId(img.category)}
          >
            <img
              src={img.offerImage}
              alt=""
              className="w-full h-full object-cover" // Ensures the image is responsive and covers the container
            />
            <div className="absolute inset-0 flex justify-between items-center px-5">
              <div className="flex flex-col gap-2 lg:gap-4 text-white p-4 lg:p-8 max-w-lg">
                {img.offer && (
                  <p className="text-lg lg:text-3xl font-bold">{img.offer}</p>
                )}
                {img.mainContent && (
                  <p className="text-xl lg:text-5xl font-bold text-black">
                    {img.mainContent}
                  </p>
                )}
                {img.subContent && (
                  <p className="text-sm lg:text-lg">{img.subContent}</p>
                )}
                {img.btnText && (
                  <button
                    onMouseEnter={() => setBtnHoverStatus(true)}
                    onMouseLeave={() => setBtnHoverStatus(false)}
                    className={`px-6 py-3 text-white border-2 transition-colors duration-500 rounded ${
                      btnHoverStatus
                        ? "bg-transparent border-white"
                        : "bg-orange-500 border-orange-500"
                    }`}
                  >
                    {img.btnText}
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Dots */}
      <div className="absolute bottom-4 left-0 right-0 flex justify-center gap-2">
        {images.map((_, i) => (
          <div
            key={i}
            className={`w-3 h-3 lg:w-4 lg:h-4 rounded-full bg-white ${
              i === viewimageIndex ? "opacity-100" : "opacity-50"
            } cursor-pointer`}
            onClick={() => handleDotClick(i)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
