import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './OrderConfirmed.css'; 
const OrderConfirmed = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/OrderPage'); 
    }, 3000);

    return () => clearTimeout(timer); 
  }, [navigate]);

  return (
    <div className="order-confirmed-container">
      <div className="confetti">
        {[...Array(100)].map((_, index) => (
          <div key={index} className="confetti-piece" />
        ))}
      </div>
      
      <div className="message">
        <h1 className="text-green">Order Confirmed!</h1>
        <p className="thank-you">Thank you for your purchase!</p>
      </div>
    </div>
  );
};

export default OrderConfirmed;
