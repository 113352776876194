import React, { useContext, useEffect, useState } from "react";
import {
  Star,
  ShareIcon,
  Cart,
  Buy,
  Tag,
  heartblack,
  heartbrown,
} from "../../assets/svg/index";
import { addToCart } from "../../Networking/CartApiAction";
import {
  getAllProducts,
  getProductDetails,
} from "../../Networking/ProductApisAction";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "../../Context/AuthContext";
import Loader from "../../acscomponents/Loader";
import ImageWithLoader from "../../acscomponents/ImageLoaderComponent";
import image1 from "../../assets/MAINIMAGE.png";
import image2 from "../../assets/MAINIMAGE.png";
import image3 from "../../assets/MAINIMAGE.png";
import image4 from "../../assets/MAINIMAGE.png";
import image5 from "../../assets/MAINIMAGE.png";
import { ProductContextStates } from "../../Context/ProductContext";
import RatingComponent from "../../acscomponents/RatingComponent";
import RateProduct from "./RatingProduct";
import {
  addFavourited,
  checkFavourited,
  deleteFavourited,
} from "../../Networking/FavouriteApisAction";
import ProductReview from "./ProductReview";
import ToastMessage from "../../acscomponents/ToastMessage";
import { ToastMsgContext } from "../../Context/ToastMessageContext";
import { CartContext, CartStates } from "../../Context/CartContext";

const ProductDetails = () => {
  const navigate = useNavigate();
  const AuthUserContextStates = useContext(Auth);
  const productContextState = useContext(ProductContextStates);
  const location = useLocation();

  // State Declarations
  const [loading, setLoading] = useState(true);
  const [productData, setProductData] = useState(null);
  const [showFullText, setShowFullText] = useState(false);
  const [showFull, setShowFull] = useState(false);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [clickImage, setClickImage] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const ToastMsgState = useContext(ToastMsgContext);
  const [expandedItemId, setExpandedItemId] = useState(null);

  const subImages = [image1, image2, image3, image4, image5, image4, image5];

  const searchParams = new URLSearchParams(location.search);
  const selectedProductId = searchParams.get("selectedProductId");
  const categoryId = searchParams.get("categoryId");
  const CartContextState = useContext(CartStates);

  const ProductAvailableOffers = [
    "Lorem iporem ipsquenatoquein nun natoque",
    "Lorem ipsum dolor sit amet consectetur. In nunc sit sed natoquein nunc sit sed in nunc sit sed natoquein nunc sit sed natoque",
    "Lorem ipsum dolor sit amet consectetur. In nunc sit sed natoquein nunc sit sed in nunc sit sed natoquein nunc sit sed in nunc sit sed natoque",
    "Lorem ipsum dolor sit amet consectetur. In nunc sit sed natoquein nunc sit sed in nunc sit sed natoquein nunc sit sed in nunc sit sed natoque",
    "Lorem ipsum dolor sit amet consectetur. In nunc sit sed natoquein nunc sit sed in nunc sit sed natoquein nunc sit sed in nunc sit sed natoque",
  ];

  const fetchProductDetails = () => {
    if (
      AuthUserContextStates.customerId !== "" ||
      AuthUserContextStates.customerId !== null
    ) {
      const bodyData = {
        requestId: selectedProductId,
        userId: AuthUserContextStates.customerId || 0,
      };
      getProductDetails(bodyData)
        .then((response) => {
          setProductData(response.data.data);
          setSimilarProducts(response.data.data.similarProducts || []);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching product details:", error);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!selectedProductId) {
      navigate("/products", { replace: true });
      return;
    }
    fetchProductDetails();
  }, [
    selectedProductId,
    AuthUserContextStates.customerId,
    ToastMsgState.getCode,
  ]);

  // useEffect(() => {
  //   if (
  //     AuthUserContextStates.customerId !== "" ||
  //     AuthUserContextStates.customerId !== null
  //   ) {
  //     const bodyData = {
  //       userId: AuthUserContextStates.customerId || 0,
  //       categoryId: categoryId,
  //       subCategoryId: 0,
  //       productCategory: 0,
  //       brandId: 0,
  //     };

  //     getAllProducts(bodyData)
  //       .then((response) => {
  //         const limitedProducts = response.data.data.listAllProductItems.slice(
  //           0,
  //           10
  //         );
  //         setAllProducts(limitedProducts);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching all products:", error);
  //       });
  //   }
  // }, [AuthUserContextStates.customerId, categoryId]);

  useEffect(() => {
    if (similarProducts && similarProducts.length > 0) {
      const initialSelectedProducts = similarProducts.map((item) => item.id);
      setSelectedProducts(initialSelectedProducts);

      const initialTotalPrice = similarProducts.reduce(
        (sum, item) => sum + (Number(item.discountedPrice) || 0),
        0
      );

      setTotalPrice(initialTotalPrice);
    }
  }, [similarProducts]);

  const handleProductDetails = (productId, categoryId) => {
    const url = `/ProductDetails?selectedProductId=${productId}&categoryId=${categoryId}`;
    window.open(url, "_blank");
  };

  const handleCheckboxChange = (id, isChecked, price) => {
    setSelectedProducts((prev) => {
      const validPrice = !isNaN(price) && price !== null ? price : 0;
      const newSelectedProducts = isChecked
        ? [...prev, id]
        : prev.filter((productId) => productId !== id);
      const newTotalPrice = isChecked
        ? totalPrice + validPrice
        : Math.max(totalPrice - validPrice, 0);

      setTotalPrice(newTotalPrice);
      return newSelectedProducts;
    });
  };

  const categoryCartData = CartContextState.getCategories;

  const handleAddCart = (productId) => {
    setLoading(true);
    if (AuthUserContextStates.customerId !== 0 || "") {
      const bodyData = {
        userId: AuthUserContextStates.customerId,
        productId: productId,
        category: categoryId,
        subCategory: productData.subCategoryId,
        productCategory: productData.productCategoryId,
        brand: productData.brandId,
        color: productData.colorId,
        unit: productData.unitId,
        productSize: productData.sizeId,
        quantity: 1,
      };
      addToCart(bodyData)
        .then((response) => {
          const message = response.data.message;
          const code = response.data.code;
          if (code === 200) {
            ToastMsgState.getIsActive(true);
            ToastMsgState.getMessage(message);
            ToastMsgState.getCode(code);
            CartContext.updateCartItems(response);
            // navigate('/Cartpage')
          }
        })
        .catch((err) => console.error("Error adding to cart:", err));
    } else {
      ToastMsgState.getIsActive(true);
      ToastMsgState.getMessage("Kindly Login ");
      ToastMsgState.getCode(201);
    }
    setLoading(false);
  };

  const handleFavourite = (isAdding) => {
    const bodyData = {
      userId: AuthUserContextStates.customerId,
      productId: productData.productId,
    };

    // Check if user is logged in
    if (!AuthUserContextStates.customerId) {
      ToastMsgState.getMessage("Kindly Login");
      ToastMsgState.getCode(201);
      ToastMsgState.getIsActive(true);
      return;
    }

    // If adding to favorites
    if (!isAdding) {
      const addFavoriteBodyData = {
        userId: AuthUserContextStates.customerId,
        productId: productData.productId,
        category: categoryId,
        subCategory: productData.subCategoryId,
        productCategory: productData.productCategoryId,
        brand: productData.brandId,
        color: productData.colorId,
        unit: productData.unitId,
        productSize: productData.sizeId,
      };

      // Add to favorites
      addFavourited(addFavoriteBodyData)
        .then((res) => {
          const { code, message } = res.data;
          ToastMsgState.getMessage(message);
          ToastMsgState.getCode(code);
          ToastMsgState.getIsActive(true);
        })
        .catch((error) => {
          console.log("Error adding to Favourites:", error);
        });
      // }
    } else {
      const deleteFavData = {
        productId: productData.productId,
        userId: AuthUserContextStates.customerId,
      };

      deleteFavourited(deleteFavData)
        .then((res) => {
          const { code, message } = res.data;
          ToastMsgState.getMessage(message);
          ToastMsgState.getCode(code);
          ToastMsgState.getIsActive(true);
        })
        .catch((error) => {
          console.log("Error removing from Favourites:", error);
        });
    }
  };

  const handleViewAll = () => {
    navigate("/ProductList", { state: { selectedCategoryId: categoryId } });
  };

  if (loading) {
    return <Loader className="blur" />;
  }

  if (!productData) {
    return <p>Product data not found.</p>;
  }

  return (
    <div className="bg-white overflow-auto ">
      {loading && <Loader className={loading ? "blur" : "blur-none"} />}
      <ToastMessage />
      <div className="grid md:flex px-1.5  h-auto ">
        {/* Main Image Left Part */}
        <div className=" flex-1 flex justify-center items-center">
          <div className="grid grid-rows-5 h-full  w-full">
            <div className="row-span-3 flex justify-center items-center p-2 md:p-5 xl:p-2 relative cursor-pointer ">
              {/* Heart Icon */}
              <div
                className="absolute top-2 md:right-6 right-4 xl:right-4 z-10 "
                onClick={() => handleFavourite(productData.favourite)}
              >
                <img
                  src={productData.favourite ? heartbrown : heartblack}
                  alt="Heart icon"
                />
              </div>
              {/* Product Image */}
              <ImageWithLoader
                src={clickImage ? clickImage : productData.image}
                imgClass="object-cover w-full h-72  mt-5 md:mt-0"
                alt="Product"
              />
            </div>
            <div className="row-span-1.5 flex p-1.5 px-6 md:px-16 justify-center items-center ">
              {/* Parent container for horizontal scrolling */}
              <div className="flex overflow-x-scroll horizontal-scroll  md:horizontal-scroll w-full gap-3 ">
                {[...subImages, productData.image].map((item, index) => (
                  <div key={index} className="shrink-0">
                    <img
                      src={item}
                      className="w-32 h-20 object-cover cursor-pointer"
                      onClick={() => setClickImage(item)}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="row-span-1 flex justify-center items-center ">
              {/* Add your content here */}
              {/* <div className="flex flex-col md:flex-row gap-5 px-3 md:px-0 "> */}
              <div className="flex gap-2 md:gap-5 px-0 ">
                <button
                  type="button"
                  className="w-36 md:w-52 h-12 font-jaldi md:text-14px lg:text-16px bg-dark-brown text-white rounded-sm font-bold flex items-center justify-center px-3 "
                  style={{
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => handleAddCart(productData.productId)}
                >
                  <img src={Cart} alt="" className="mr-5" />
                  ADD TO CART
                </button>
                {/* <button
                  type="button"
                  className="w-36 md:w-52 h-12 font-jaldi text-md md:text-14px lg:text-16px bg-bismi-yellow text-white rounded-sm font-bold flex items-center justify-center px-3 "
                  style={{
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                    whiteSpace: "nowrap",
                  }}
                >
                  <img src={Buy} alt="" className="mr-5" />
                  BUY NOW
                </button> */}
              </div>
            </div>
          </div>
        </div>
        {/* Right Part */}
        <div className=" flex-1 overflow-y-scroll no-scroll h-[88vh] px-5 py-1.5 ">
          {/* <p className="flex gap-2 text-16px font-jaldi place-content-end font-black lowercase tracking-wider cursor-pointer"><img src={ShareIcon} />Share</p> */}
          {/* part 1* */}
          <p className="text-20px mt-1 font-jaldi font-bold opacity-80 mb-2 break-words">
            {productData.productName ? productData.productName : null} (
            {productData.size ? productData.size : null})
          </p>
          <div className="flex items-center mb-2">
            <p className="inline-flex bg-lightgreen px-2 py-1 text-13px mr-2 rounded-sm text-greenrating">
              <span className="font-bold flex font-quicksand items-center">
                <img src={Star} alt="" className="mr-2 -ml-1" />
                {productData.rating ? productData.rating : null}
              </span>
            </p>
            <p className="font-quicksand text-13px">
              12,925 Ratings & 1,064 Reviews
            </p>
          </div>
          <div className="flex gap-6 mt-5">
            <p className="text-16px opacity-90 font-QuattrocentoSans font-bold">
              Description
            </p>
            <div className="grid">
              <span
                className="text-sm text-pretty font-normal font-QuattrocentoSans content-span"
                dangerouslySetInnerHTML={{
                  __html: productData.description, // Render the HTML content directly
                }}
              />
            </div>
          </div>

          <p className="text-dark-green text-15px mt-3 mb-1 font-quicksand opacity-90 font-bold">
            Special Price
          </p>
          <p className="text-25px font-bold font-quicksand text-black">
            Rs.{productData.priceWithGst}
          </p>
          <p className="text-16px font-quicksand text-dark-brown font-semibold mt-3 mb-3">
            Available Offers
          </p>

          <div className="text-13px mb-2 max-w-full overflow-wrap break-word font-quicksand text-black-opacity-60% ">
            {ProductAvailableOffers.map((offer, index) => (
              <div className="flex items-start mb-5" key={index}>
                <img src={Tag} alt="" className="" />
                <p className="ml-4 font-semibold">{offer}</p>
              </div>
            ))}
            <div className="flex gap-9 ">
              <p className="text-16px text-dark-brown font-QuattrocentoSans font-bold">
                Delivery
              </p>
              <p className="font-QuattrocentoSans text-16px  font-bold cursor-pointer ">
                {showFullText
                  ? "Lorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssectetur, consecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssectetur"
                  : "Lorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssectetur, consecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssectetur, consecteturLorem ipsum dolor sit amet conssecteturLorem ipsum dolor sit amet conssectetur".slice(
                      0,
                      100
                    )}
                <span
                  className="text-14px text-gray-400  font-bold font-quicksand cursor-pointer"
                  onClick={() => setShowFullText(!showFullText)}
                >
                  {showFullText ? "Hide Details" : " ...View Details"}
                </span>
              </p>
            </div>
            <div className="grid mt-3 w-full ">
              <div className="flex flex-col my-5 lg:flex-row text-16px font-QuattrocentoSans font-bold gap-10">
                <div className="flex gap-7 ">
                  <p className="text-dark-brown ">Highlights</p>
                  <div className="opacity-70 text-black ">
                    <p>- Raw</p>
                    <p className="text-wrap">- Comes in a Pouch</p>
                  </div>
                </div>
                <div className="flex my-5 lg:my-0 lg:ms-4 gap-10 lg:gap-5 ">
                  <p className="text-dark-brown ">Services</p>
                  <p className="text-wrap  opacity-70 text-black ">
                    - Cash on delivery available
                  </p>
                </div>
              </div>
              <div className="flex mb-2 justify-start">
                <div className="flex">
                  <p className="text-16px text-dark-brown font-QuattrocentoSans font-bold ">
                    Seller
                  </p>
                  <p className="opacity-90 font-bold text-dark-brown  font-QuattrocentoSans text-16px mr-15 ml-[58px] break-words max-w-[300px]">
                    Super ComNet
                  </p>
                </div>
              </div>
              <div className=" gap-8 ml-16">
                <p className="opacity-70 font-bold text-black font-QuattrocentoSans text-16px ml-8 break-words max-w-[300px]">
                  - No Returns Allowed
                </p>
                <p className="opacity-70 font-bold text-black font-QuattrocentoSans text-16px ml-8 break-words max-w-[300px]">
                  - GST Invoice Available
                </p>
              </div>
            </div>
          </div>
          <div className="md:flex-row px-5">
            <p className="py-1 text-23px md:text-20px font-semibold font-QuattrocentoSans text-dark-brown">
              Specification
            </p>
            <div className="bg-white w-full py-2">
              <p className="text-lg md:text-16px font-QuattrocentoSans text-black font-semibold">
                In The Box
              </p>
              <div className="grid grid-cols-3 text-md md:text-md pt-2">
                <p className=" font-normal font-QuattrocentoSans text-dark-brown">
                  Pack Of
                </p>
                <p className=" font-normal font-QuattrocentoSans text-black opacity-60">
                  {productData.minimumOrder ? productData.minimumOrder : null}
                </p>
              </div>
            </div>
            <div className="bg-white w-full py-2">
              <p className="text-lg md:text-16px font-QuattrocentoSans text-black font-semibold">
                General
              </p>
              <div className="grid grid-cols-3 text-md md:text-md pt-2">
                <p className="font-normal font-QuattrocentoSans text-dark-brown">
                  Brand
                </p>
                <p className="font-normal font-QuattrocentoSans text-black opacity-60">
                  {productData.brandName ? productData.brandName : "N/A"}
                </p>
              </div>
              <div className="grid grid-cols-3 text-md md:text-md pt-2">
                <p className="font-normal font-QuattrocentoSans text-dark-brown">
                  Quantity
                </p>
                <p className="font-normal font-QuattrocentoSans text-black opacity-60">
                  900g
                </p>
              </div>
              <div className="grid grid-cols-3 text-md md:text-md pt-2">
                <p className="font-normal font-QuattrocentoSans text-dark-brown">
                  Type
                </p>
                <p className="font-normal font-QuattrocentoSans text-black opacity-60">
                  Almonds
                </p>
              </div>
            </div>
          </div>
          <div className="w-full space-y-2">
            <div className="border-t-4">
              <RateProduct productId={productData.productId} />
            </div>
            <div className="border-t-4 py-4">
              <RatingComponent />
            </div>
            <div className="border-t-4 py-4">
              <ProductReview id={productData.productId} />
            </div>
          </div>
        </div>
      </div>

      {/* freequently Part */}
      {/* <div className={`${similarProducts.length === 0 ? "hidden" : "block "} `}>
        {/* Heading *
        <h3 className="text-lg sm:text-lg md:text-2xl lg:text-[25px] px-5 font-Inter font-bold text-dark-brown pt-2.5 capitalize ">
          Frequently bought together
        </h3>

        {/* Frequently Bought Together Section *
        <div className=" flex flex-col xl:flex-row md:justify-between justify-center md:items-start items-center  md:px-4 lg:px-10 py-2 ">
          {/* Similar Products *
          <div className="flex flex-nowrap gap-3 lg:gap-5 flex-1 justify-center items-center px-3 py-2">
            {similarProducts?.length > 0 &&
              similarProducts.map((item, index) => (
                <div className="flex justify-center items-center h-44">
                  <div
                    className="flex flex-col items-center gap-3 w-full md:w-40 shadow-md pb-1.5"
                    key={item.id}
                  >
                    <div className="relative flex justify-center items-center w-full">
                      <ImageWithLoader
                        src={item.productImage}
                        alt={item.productName || "Product image"}
                        imgClass="w-24 h-20 p-1.5 md:w-36 md:h-28 object-contain rounded-t-lg"
                      />

                      {/* Checkbox 
                      <div className="absolute -top-1 right-7 md:right-0  ">
                        <input
                          className="w-4 h-4 p-0.5 outline-offset-2 accent-dark-brown cursor-pointer"
                          type="checkbox"
                          id={`checkbox-${item.id}`}
                          checked={selectedProducts.includes(item.id)}
                          onChange={(e) =>
                            handleCheckboxChange(
                              item.id,
                              e.target.checked,
                              item.discountedPrice
                            )
                          }
                        />
                      </div>
                    </div>

                    {/* Product Name *
                    <p className="text-xs md:text-14px h-10 text-wrap text-amber-950 tracking-wide font-semibold font-Inter text-center">
                      {item.productName.slice(0, 30)}
                    </p>
                  </div>
                  <div className="text-20px font-black font-QuattrocentoSans  ms-3">
                    {index < similarProducts.length - 1 && <span>+</span>}
                  </div>
                </div>
              ))}
          </div> 

          {/* Product Options 
          <div className="flex-1 md:text-18px px-2 lg:px-5 mt-4 md:mt-0">
            <div className="grid grid-cols-3 py-1.5 justify-stretch items-center border-b-4">
              <div className="col-span-1 text-md font-semibold text-gray-600 text-center">
                Product Name
              </div>
              <div className="col-span-1 text-md font-semibold text-gray-600 text-center">
                Size
              </div>
              <div className="col-span-1 text-md font-semibold text-gray-600 text-center">
                Price
              </div>
            </div>

            {productData?.similarProducts?.map((product, index) => (
              <div
                key={product.id}
                className="grid grid-cols-3 justify-start items-start space-x-0 border-b py-2.5 max-h-12"
              >
                {/* Product Info 
                <div className="text-gray-700 text-sm h-10 px-1 text-wrap font-semibold col-span-1 text-center">
                  {product.productName.slice(0, 30)}
                </div>

                {/* Size Selector 
                <div className="col-span-1 px-5 text-center">
                  <select className="border-gray-300 rounded-md shadow-sm text-sm">
                    <option>{product.size || "M"}</option>
                  </select>
                </div>

                {/* Price *
                <div className="text-amber-900 font-bold text-sm text-center">
                  {product.oldPrice && (
                    <span className="line-through text-gray-500 mr-1">
                      Rs. {Number(product.oldPrice).toFixed(2)}
                    </span>
                  )}
                  {isNaN(product.discountedPrice) ||
                  product.discountedPrice === null ||
                  product.discountedPrice === "" ? (
                    <span>Rs. 0</span>
                  ) : (
                    <span>
                      Rs. {Number(product.discountedPrice).toFixed(2)}
                    </span>
                  )}
                </div>
              </div>
            ))} *

            {/* Only show the "Add to Cart" button for the last item *
            {productData?.similarProducts &&
              productData.similarProducts.length > 0 && (
                <div className="mt-2 md:mt-0 col-span-3 flex justify-end pt-2 font-Inter">
                  <button
                    onClick={handleAddCart}
                    className="bg-amber-900  text-white px-2 lg:px-4 py-2 rounded-md hover:bg-white hover:text-black hover:border-dark-brown border focus:outline-none text-sm font-semibold transition duration-200 "
                  >
                    Buy at{" "}
                    <span className="hover:font-bold">
                      {" "}
                      {Number(totalPrice).toFixed(2) || 0}
                    </span>
                  </button>
                </div>
              )}
          </div>
        </div>
      </div> */}

      {/** similar products */}
      <div className="py-10 ">
        <div className="flex justify-between items-center">
          <h3 className="text-lg sm:text-lg md:text-2xl lg:text-[25px] px-5 font-Inter font-bold text-dark-brown pt-0 lg:pt-2.5 capitalize text-nowrap">
            Similar Products
          </h3>
          <button
            className="mx-5 px-8 font-jaldi text-18px underline-offset-2 hover:underline decoration-amber-800 font-bold text-dark-brown text-nowrap"
            onClick={handleViewAll}
          >
            View All
          </button>
        </div>
        <div className="grid md:flex gap-5 justify-center md:justify-start overflow-hidden no-scrollbar px-3.5 py-2.5">
          {productData?.similarProducts &&
            productData?.similarProducts.map((product) => (
              <div
                key={product.id}
                className="min-w-[16rem] max-w-xs rounded-lg shadow-lg cursor-pointer"
                onClick={() => handleProductDetails(product.id, categoryId)}
              >
                <div className="p-2.5">
                  <img
                    src={product.productImage}
                    alt={product.productName}
                    className="object-contain h-36 md:min-h-40 md:max-h-44 w-full break-words"
                  />
                </div>
                <div className="px-3.5 grid grid-cols-2 py-1.5 ">
                  <div className="col-span-1 ">
                    <p className="text-14px font-jaldi w-52 overflow-hidden px-2 h-10 text-black-opacity-80">
                      {product.productName.slice(0, 50)}
                    </p>
                    <h3 className="font-semibold text-base font-QuattrocentoSans">
                      {product.product}
                    </h3>
                    <h4 className="text-red font-semibold tracking-wide py-1.5 px-2 text-12px md:text-md text-nowrap">
                      <span>&#8377;</span>{" "}
                      {product.discountedPrice ? product.discountedPrice : "1"}{" "}
                      /-
                    </h4>
                  </div>
                  <div className="text-end col-span-1">
                    <p className="flex justify-end text-18px font-jaldi font-semibold">
                      <span className="flex px-1.5 rounded-md bg-lime-500 shadow-image-backdrop">
                        {product.ratings} <img src={Star} className="px-0.5" />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
