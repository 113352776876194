import { createContext, useState } from "react";

const SearchState = createContext();

const SearchContext = (props) => {
    const [searchData, setSearchData] = useState([]);
    const [message, setMessage] = useState("");

    return <SearchState.Provider value={{ searchData, setSearchData, message, setMessage }}>
        {props.children}
    </SearchState.Provider>
};

export { SearchState, SearchContext };