import { AxiosConfig } from "./AxiosConfig";

export const getAddress = async (id, userId) => {
  return await AxiosConfig.post('/user/get/all-address', null, {
    params: {
      requestId: id,
      userId: userId
    }
  });
};

export const getStateAndCity = async () => {
  return await AxiosConfig.get("/master/get/places");
};

export const addressTypes = async () => {
  return await AxiosConfig.get("/master/get/address-types");
};


export const addAddress = async (bodyData) => {
  return await AxiosConfig.post('/user/add/new-address', bodyData)
};

export const defaultAddressApi = async (bodyData) => {
  return await AxiosConfig.post("/user/update/default-address", bodyData);
};
