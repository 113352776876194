import React, { useContext, useEffect, useState } from "react";
import LoginImage from "../../assets/Login2.png";
import { Link, useNavigate } from "react-router-dom";
import { Phone } from "../../assets/svg/index";
import {
  checkPhone,
  verifyPhone,
  resendOtpDetails
} from "../../Networking/AuthApisActions";
import { Auth } from "../../Context/AuthContext";
import Loader from "../../acscomponents/Loader";
import CryptoJS from "crypto-js";
import ToastMessage from "../../acscomponents/ToastMessage";
import ImageWithLoader from "../../acscomponents/ImageLoaderComponent";
import { ToastMsgContext } from "../../Context/ToastMessageContext";

function Login() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [timeLeft, setTimeLeft] = useState(3);
  const [isVisibleSts, setIsVisibleSts] = useState(false);
  const navigate = useNavigate();
  const authUserContext = useContext(Auth);
  const ToastMsgState = useContext(ToastMsgContext)

  const setEmpty = () => {
    setIsVisibleSts(false);
    setError('');
    setLoading(true);
    setIsVisibleSts(true);
  };

  const startTimer = () => {
    setTimeLeft(3);
    const timer = setInterval(() => {
      setTimeLeft(prev => {
        if (prev === 1) clearInterval(timer);
        return prev - 1;
      });
    }, 1000);
  };

  const handleOtp = (event) => {
    event.preventDefault();
    setEmpty();
    if (phoneNumber.length !== 10) {
      setError("Phone number must be exactly 10 digits");
      setLoading(false)
      return;
    }
    startTimer();
    checkPhone({ phone: phoneNumber, otp: '' }).then((response) => {
      const data = response.data;
      if (data.code === 200) {
        setIsOtpSent(true);
        authUserContext.updatecheckOtp(data.data);
        ToastMsgState.getMessage('OTP sent to registered mobile number');
      } else {
        setError(data.message);
      }
      ToastMsgState.getCode(data.code);
      ToastMsgState.getIsActive(true);
      setLoading(false);
    }).catch(() => {
      setError("Failed to send OTP");
      setLoading(false);
    });
  };

  const resendOtp = (event) => {
    event.preventDefault();
    ToastMsgState.getMessage('');
    setEmpty();
    startTimer();
    resendOtpDetails(phoneNumber).then((response) => {
      const data = response.data;
      if (data.code === 200) {
        setIsOtpSent(true);
        ToastMsgState.getMessage('OTP resent successfully');

      } else {
        setError(data.status);
      }
      ToastMsgState.getCode(data.code);
      ToastMsgState.getIsActive(true);
      setLoading(false);
    }).catch(() => {
      setError("Failed to resend OTP");
      setLoading(false);
    });
  };

  const verifyPhoneApi = () => {
    if (otp !== authUserContext.otp) {
      setError("Invalid OTP");
      setLoading(false)
      return;
    }
    verifyPhone({
      id: authUserContext.userId,
      otp: authUserContext.otp
    }).then((response) => {
      const data = response.data;
      authUserContext.updateUserData(data.data);
      const isRegistered = authUserContext.userData.registered;
      const userId = data.data.id;
      if (userId && isRegistered === true) {
        const encryptedUserId = CryptoJS.AES.encrypt(userId.toString(), 'UserKey').toString();
        localStorage.setItem('UserKey', encryptedUserId);
        authUserContext.setCustomerId(userId);
        navigate("/");
      } else {
        navigate('/register', { state: { phoneNumber, userId } });
      }
      setLoading(false);
    }).catch(() => {
      setError("Failed to verify phone");
      setLoading(false);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setEmpty();
    setLoading(false);
    if (otp.length !== 6) {
      setError("Please enter a valid 6-digit OTP.");
      return;
    }
    verifyPhoneApi();
  };

  useEffect(() => {
    if (timeLeft <= 0) setTimeLeft(0);
  }, [timeLeft]);
  return (
    <>
      {loading && <Loader className={loading ? "blur" : "blur-none"} />}
      <ToastMessage />
      <div className="flex flex-col md:flex-row h-screen border max-md:justify-center bg-white">
        <div className="flex items-center justify-center w-full md:w-1/2 bg-white shadow-md p-5">
          <div className="w-full max-w-md px-5 py-10 rounded-xl border border-light-brown shadow">
            <p className="text-center font-jaldi text-lg font-normal capitalize">
              Please{" "}
              <span className="text-dark-brown font-semibold tracking-wider">
                login/Signup
              </span>{" "}
              with your account details
            </p>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="mt-5 relative">
                <label htmlFor="phoneNumber" className="block text-lg font-jaldi">
                  Phone Number
                </label>
                <div className="relative">
                  <input
                    type="number"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                      setError("");
                    }}
                    className="w-full pl-3 pr-10 py-2 border border-light-brown opacity-90 rounded-md focus:outline-none focus:ring-brown-500 focus:border-brown-500"
                    required
                  />
                  <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                    <ImageWithLoader
                      src={Phone}
                      alt="Phone icon"
                      imgClass="h-5 w-5 text-gray-400"
                    />
                  </div>
                </div>
              </div>
              {error && <p className="text-sm text-red">{error}</p>}
              {isOtpSent && (
                <div className="space-y-2">
                  <label htmlFor="otp" className="block text-lg font-jaldi">
                    OTP
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="otp"
                      name="otp"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      className="w-full pr-10 pl-3 py-2 border border-light-brown opacity-90 rounded-md shadow-sm focus:outline-none focus:ring-brown-500 focus:border-brown-500"
                    />
                  </div>
                </div>
              )}
              <div className="text-right grid grid-cols-2">
                <div className="col-span-1 flex gap-3">
                  <p className={`${isOtpSent ? "block" : "hidden"} ${timeLeft === 0 ? "opacity-0" : "opacity-100 rounded-full border text-sm p-1"}`}>
                    {timeLeft}
                  </p>
                  <button
                    className={`capitalize text-light-brown ${timeLeft === 0 ? "opacity-100" : "opacity-0 pointer-events-none"} transition-opacity duration-500`}
                    onClick={timeLeft === 0 ? resendOtp : null}
                  >
                    Resend OTP
                  </button>
                </div>
                <div className="col-span-1 text-nowrap">
                  {/* <Link to="/forgotPassword" className="text-sm font-medium font-Inter text-dark-brown"> */}
                    Forgot Password?
                  {/* </Link> */}
                </div>
              </div>
              <div className="flex items-center justify-center">
                {!isOtpSent ? (
                  <button
                    type="button"
                    className="px-10 py-2 text-white bg-dark-brown font-jaldi text-lg mb-5 rounded-3xl"
                    onClick={handleOtp}
                  >
                    Get OTP
                  </button>
                ) : (
                  <button
                    type="submit"
                    className={`px-10 py-2 text-white bg-dark-brown font-jaldi text-lg mb-5 rounded-3xl ${otp && otp.length !== 6 ? "opacity-50 cursor-not-allowed" : ""}`}
                  >
                    Sign In
                  </button>
                )}
              </div>
            </form>
            <div className="text-center mt-5 hidden">
              <p className="text-lg font-inter text-gray-600">
                Don’t have an account?{" "}
                <Link to="/register" className="font-bold text-dark-brown">
                  Sign up
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="hidden md:flex w-full md:w-1/2 bg-light-brown items-center justify-center p-5">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-white font-jaldi mb-10">
              Login/SignUp to your account
            </h2>
            <img src={LoginImage} alt="Thumbnail" className="max-w-full h-auto" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
