import React, { useState, useEffect, useContext } from "react";
import {
  SearchIcon,
  Star,
  browncarticon,
  carticon,
  browndeleteicon,
  deleteicon,
} from "../../assets/svg/index";
import {
  getAllfavourited,
  deleteFavourited,
} from "../../Networking/FavouriteApisAction";
import { Auth } from "../../Context/AuthContext";
import Loader from "../../acscomponents/Loader";
import { addToCart } from "../../Networking/CartApiAction";
import { useNavigate } from "react-router-dom";
import ImageWithLoader from "../../acscomponents/ImageLoaderComponent";
import { ToastMsgContext } from "../../Context/ToastMessageContext";
import ToastMessage from "../../acscomponents/ToastMessage";

const Wishlist = () => {
  const [deleteModelShowId, setDeleteModelShowId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteHoverStatus, setDeleteHoverStatus] = useState(null);
  const [cartHoverStatus, setCartHoverStatus] = useState(null);
  const [favouriteProducts, setFavouriteProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [notification, setNotification] = useState("");

  const AuthContextuser = useContext(Auth);
  const toastMsgState = useContext(ToastMsgContext);
  const navigate = useNavigate();

  useEffect(() => {
    const bodyData = {
      requestId: AuthContextuser.customerId,
      userId: AuthContextuser.customerId,
    };
    getAllfavourited(bodyData).then((response) => {
      const data = response.data.data;
      const code = response.data.code;

      console.log(data, "get fav");

      if (code === 200) {
        setFavouriteProducts(data);
      } else {
        setFavouriteProducts([]);
      }
      setLoading(false);
    });
  }, [AuthContextuser.customerId, toastMsgState.code]);

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredProducts = favouriteProducts.filter((product) => {
    const category = product.productCategory || "";
    return category.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const onDeleteClick = () => {
    console.log(selectedId, "sel");

    if (selectedId !== null) {
      const bodyData = {
        productId: selectedId,
        userId: AuthContextuser.customerId,
      };

      deleteFavourited(bodyData).then((res) => {
        const message = res.data.message;
        const code = res.data.code;
        toastMsgState.getMessage(message);
        toastMsgState.getCode(code);
        toastMsgState.getIsActive(true);
        setDeleteModelShowId(null);
        setFavouriteProducts((prev) =>
          prev.filter((product) => product.id !== selectedId)
        );
      });
    }
  };

  const handleDeleteConfirmation = () => {
    setIsDeleteConfirmed(true);
  };

  useEffect(() => {
    if (isDeleteConfirmed) {
      onDeleteClick();
      setIsDeleteConfirmed(false);
    }
  }, [isDeleteConfirmed]);

  const handleSetDeleteProduct = (productId) => {
    setSelectedId(productId);
    setDeleteModelShowId(productId);
  };

  const handleDeleteCancel = () => {
    setDeleteModelShowId(null);
  };

  const handleAddCart = (productId) => {
    const filteredProduct = favouriteProducts.find(
      (product) => product.id === productId
    );
    if (!filteredProduct) return;

    const bodyData = {
      userId: AuthContextuser.customerId,
      productId: filteredProduct.productId,
      category: filteredProduct.categoryId,
      subCategory: filteredProduct.subCategoryId,
      productCategory: filteredProduct.productCategoryId,
      brand: filteredProduct.brandId,
      color: filteredProduct.colorId,
      unit: filteredProduct.unitId,
      productSize: filteredProduct.productSizeId,
      quantity: 1,
      active: true,
    };

    addToCart(bodyData).then((res) => {
      const message = res.data.message;
      const code = res.data.code;
      console.log(message, "message");
      console.log(code, "message code");
      setTimeout(() => setNotification(""), 3000);
      toastMsgState.getMessage(message);
      toastMsgState.getCode(code);
      toastMsgState.getIsActive(true);
      navigate("/Cartpage");
    });
  };

  return (
    <div className="bg-white px-4 md:px-10 py-6">
      {loading && <Loader className={loading ? "blur" : "blur-none"} />}
      <ToastMessage />
      <div className="flex flex-col md:flex-row bg-dark-brown p-3 rounded-lg justify-between mb-4">
        <h3 className="text-white font-Inter font-medium text-lg">
          My Wishlist <span>({favouriteProducts.length})</span>
        </h3>
        <div className="bg-white flex items-center px-3 py-2 rounded-md mt-3 md:mt-0">
          <img src={SearchIcon} alt="searchIcon" className="h-5 w-5" />
          <input
            type="text"
            placeholder="Search..."
            className="outline-none text-sm font-Inter pl-2 w-full"
            onChange={handleSearchTermChange}
          />
        </div>
      </div>

      <div className="min-h-64">
        {filteredProducts.length === 0 ? (
          <p className="text-center text-gray-500">
            No products found in your wishlist.
          </p>
        ) : (
          filteredProducts.map((product) => (
            <div
              key={product.id}
              className="flex flex-col md:flex-row md:grid grid-cols-4 py-1 px-3 justify-center border-b-2"
            >
              <div className="flex flex-col md:flex-row items-start justify-start col-span-2 space-y-4 md:space-y-0 md:space-x-4">
                <div className="flex items-center gap-2">
                  <button
                    className="hover:opacity-75 transition"
                    onClick={() => handleSetDeleteProduct(product.productId)}
                    onMouseEnter={() => setDeleteHoverStatus(product.id)}
                    onMouseLeave={() => setDeleteHoverStatus(null)}
                  >
                    <img
                      className="w-8 h-8 md:w-4 md:h-4"
                      src={
                        deleteHoverStatus === product.id
                          ? browndeleteicon
                          : deleteicon
                      }
                      alt="Delete Icon"
                    />
                  </button>
                  <ImageWithLoader
                    src={product.productImage}
                    alt="Product"
                    containerClass="w-36 h-24 md:w-36 md:h-24"
                    imgClass="object-contain w-full h-full"
                  />
                  <div className="flex flex-col">
                    <h4 className="text-md font-medium text-dark-brown px-2 md:px-4">
                      {product.productName}
                    </h4>
                    <h4 className="text-12px text-wrap text-justify font-medium text-dark-brown px-2 md:px-4">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Ipsa libero officiis est eaque dolorum deserunt tenetur
                      officia repellat.
                    </h4>
                  </div>
                </div>
              </div>

              <div className="grid md:flex md:col-span-1 items-center justify-between mt-2 md:mt-0">
                <p className="font-bold text-redprice text-lg">
                  RS. <span>{Number(product.productPrice).toFixed(2)}</span>
                </p>
                <p className="text-emerald-800 font-medium text-sm">In stock</p>
              </div>
              <div className="md:flex col-span-1 items-center justify-center mt-2 md:mt-0">
                <button
                  className="flex items-center bg-dark-brown hover:text-white py-1 px-2 text-12px md:text-sm font-medium font-quicksand rounded-md hover:bg-gray-700 transition"
                  onClick={() => handleAddCart(product.id)}
                  onMouseEnter={() => setCartHoverStatus(product.id)}
                  onMouseLeave={() => setCartHoverStatus(null)}
                >
                  <img
                    src={
                      cartHoverStatus === product.id ? browncarticon : carticon
                    }
                    alt="Cart Icon"
                  />
                  <span className="text-nowrap px-2 text-white">
                    Move to Cart
                  </span>
                </button>
              </div>
            </div>
          ))
        )}
      </div>
      {deleteModelShowId && (
        <div className="fixed inset-0 flex items-center justify-center bg-black-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg border border-dark-brown">
            <h3 className="text-lg font-semibold mb-4">
              Are you sure you want to delete this item?
            </h3>
            <div className="flex justify-between font-semibold">
              <button
                className="bg-modelred text-white py-2 px-4 rounded"
                onClick={handleDeleteConfirmation}
              >
                Yes, Delete
              </button>
              <button
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded"
                onClick={handleDeleteCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {notification && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded shadow-md">
          {notification}
        </div>
      )}
    </div>
  );
};

export default Wishlist;
