import React from 'react';
import "./rating.css"

const RatingComponent = ({ RatingreviewData }) => {

    const widthPercentage = (reviews, totalReviewer) => {
        return totalReviewer ? `${(reviews / totalReviewer) * 100}%` : '0%';
    };

    const progressBarColor = (reviews) => {
        if (reviews > 5000) {
            return '#4CAF50';
        } else if (reviews > 2000) {
            return '#FFA900';
        } else if (reviews > 1000) {
            return '#CCA144';
        } else if (reviews <= 0) {
            return '#D9D9D9';
        } else {
            return '#FF0000';
        }
    };

    const validData = RatingreviewData && RatingreviewData[0] && Array.isArray(RatingreviewData[0].ratings);
    const ratings = validData ? RatingreviewData[0].ratings : [{ value: "5", reviews: 0 }, { value: "4", reviews: 0 }, { value: "3", reviews: 0 }, { value: "2", reviews: 0 }, { value: "1", reviews: 0 }];
    const totalReviewer = validData ? RatingreviewData[0].totalReviewer : 0;

    return (
        <div className="rating-container">
            <div>
                <h3 className="rating-title">Ratings & Reviews</h3>
            </div>
            <div className="rating-content">
                <div className="rating-score">
                    <p>4.3 <span>&#9733;</span></p>
                </div>
                <div className="rating-bars">
                    {ratings.map((item, index) => (
                        <div key={index} className="rating-bar-item">
                            <p>{item.value} &#9733;</p>
                            <div className="rating-progress-background">
                                <div
                                    className="rating-progress-fill"
                                    style={{
                                        width: widthPercentage(item.reviews, totalReviewer),
                                        backgroundColor: progressBarColor(item.reviews),
                                    }}
                                ></div>
                            </div>
                            <div className="rating-count">
                                <span>{item.reviews}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RatingComponent;
