import React, { createContext, useState } from 'react';

const ToastMsgContext = createContext();

const ToastMessageContext = (props) => {
    const [code, setCode] = useState();
    const [message, setMessage] = useState();
    const [isActive, setIsActive] = useState(false);

    const getCode = (apiCode) => {
        setCode(apiCode);
    };

    const getMessage = (apiMessage) => {
        setMessage(apiMessage);
    };

    const getIsActive = (apiIsActive) => {
        setIsActive(apiIsActive);
    };

    // Function to reset the toast values after the notification is done
    const resetToast = () => {
        setCode(null);
        setMessage(null);
        setIsActive(false);
    };

    return (
        <ToastMsgContext.Provider 
            value={{ code, message, isActive, getCode, getMessage, getIsActive, resetToast }}
        >
            {props.children}
        </ToastMsgContext.Provider>
    );
};

export { ToastMsgContext, ToastMessageContext };
