import React, { useState, useEffect, useContext } from "react";
import Img1 from "../../assets/Rectangle 390.png";
import { SearchIcon, TickIcon } from "../../assets/svg";
import { getOrderItems } from "../../Networking/OrderApiAction";
import { Link } from "react-router-dom";
import { Auth } from "../../Context/AuthContext";
import ImageWithLoader from "../../acscomponents/ImageLoaderComponent";
import Breadcrumb from "../product/Breadcrumb";
import { OrderState } from "../../Context/OrderContext";

const OrdersPage = () => {
  const orderContext = useContext(OrderState);
  const AuthUserContext = useContext(Auth);
  const [content, setContent] = useState(null);
  const [filteredOrderItems, setFilteredOrderItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [orderStatus, setOrderStatus] = useState([
    { orderId: 1, orderStatusType: "On the way", status: false },
    { orderId: 2, orderStatusType: "Delivered", status: false },
    { orderId: 3, orderStatusType: "Cancelled", status: false },
    { orderId: 4, orderStatusType: "Returned", status: false },
  ]);
  const [orderTime, setOrderTime] = useState([
    { orderTimeId: 1, orderTimeType: "Last 30 days", orderTimeStatus: false },
    { orderTimeId: 2, orderTimeType: "2020", orderTimeStatus: false },
    { orderTimeId: 3, orderTimeType: "2021", orderTimeStatus: false },
    { orderTimeId: 4, orderTimeType: "2022", orderTimeStatus: false },
  ]);

  useEffect(() => {
    const handleSearch = () => {
      if (!searchQuery) {
        setFilteredOrderItems(orderContext.getAllOrder);
      } else {
        const filteredItems = orderContext.getAllOrder?.filter((item) =>
          item.productName.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredOrderItems(filteredItems);
      }
    };
    handleSearch();
  }, [searchQuery, orderContext.getAllOrder]);

  useEffect(() => {
    const bodyData = {
      requestId: AuthUserContext.customerId,
      userId: AuthUserContext.customerId,
    };
    getOrderItems(bodyData).then((response) => {
      const data = response?.data?.data || [];
      const allItems = data.reduce((acc, order) => {
        return acc.concat(order.orderItems);
      }, []);
      orderContext.updateGetOrders(allItems);
      setFilteredOrderItems(allItems);
      console.log("getAllOrder details", allItems);
    });
  }, [AuthUserContext.customerId]);

  const handleOrderStatus = (id) => {
    setOrderStatus(
      orderStatus.map((itm) =>
        itm.orderId === id ? { ...itm, status: !itm.status } : itm
      )
    );
  };

  const handleTimeStatus = (id) => {
    setOrderTime(
      orderTime.map((orderTimeItem) =>
        orderTimeItem.orderTimeId === id
          ? {
              ...orderTimeItem,
              orderTimeStatus: !orderTimeItem.orderTimeStatus,
            }
          : orderTimeItem
      )
    );
  };

  const handleContent = (id) => {
    setContent(id);
  };

  return (
    <>
      <div className="flex flex-col md:flex-row bg-white py-5">
        <div className="flex flex-col w-full md:w-1/4 p-4">
          <div className="shadow-2xl bg-white mb-5 p-4">
            <h5 className="font-semibold font-Inter text-lg text-dark-brown tracking-wide">
              Filters
            </h5>
          </div>
          <div className="shadow-2xl bg-dark-brown p-4 mb-5">
            <h5 className="tracking-wide text-white font-Inter font-semibold text-lg">
              ORDER STATUS
            </h5>
          </div>
          <div className="shadow-2xl bg-white p-4 mb-5">
            {orderStatus.map((orderState) => (
              <div className="flex items-center mb-3" key={orderState.orderId}>
                <div
                  className={`border border-slate-700 opacity-35 w-4 h-4 cursor-pointer ${
                    orderState.status ? "flex items-center justify-center" : ""
                  }`}
                  onClick={() => handleOrderStatus(orderState.orderId)}
                >
                  {orderState.status && (
                    <img className="w-3" src={TickIcon} alt="Tick Icon" />
                  )}
                </div>
                <span className="ml-2 text-black-opacity-60 font-Inter font-semibold">
                  {orderState.orderStatusType}
                </span>
              </div>
            ))}
          </div>
          <div className="shadow-2xl bg-dark-brown p-4 mb-5">
            <h5 className="tracking-wide text-white font-Inter font-semibold text-lg">
              ORDER TIME
            </h5>
          </div>
          <div className="shadow-2xl bg-white p-4 mb-5">
            {orderTime.map((orderTimeItem) => (
              <div
                className="flex items-center mb-3"
                key={orderTimeItem.orderTimeId}
              >
                <div
                  className={`border border-slate-700 opacity-35 w-4 h-4 cursor-pointer ${
                    orderTimeItem.orderTimeStatus
                      ? "flex items-center justify-center"
                      : ""
                  }`}
                  onClick={() => handleTimeStatus(orderTimeItem.orderTimeId)}
                >
                  {orderTimeItem.orderTimeStatus && (
                    <img className="w-3" src={TickIcon} alt="Tick Icon" />
                  )}
                </div>
                <span className="ml-2 text-black-opacity-60 font-Inter font-semibold">
                  {orderTimeItem.orderTimeType}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col w-full md:w-3/4 p-4">
          <Breadcrumb />
          <h1 className="text-dark-brown font-medium text-xl mb-4">
            My Orders
          </h1>
          <div className="flex items-center mb-4">
            <input
              className="flex-1 h-10 px-4 border border-dark-brown rounded-l-md focus:outline-none"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search your Orders Here"
            />
            <button className="bg-dark-brown text-white py-2 px-4 rounded-r-md font-Inter font-bold text-sm">
              <img
                className="w-4 h-4 inline"
                src={SearchIcon}
                alt="Search Icon"
              />
              Search Orders
            </button>
          </div>
          <div className="flex flex-col gap-4 overflow-scroll no-scrollbar h-screen">
            {filteredOrderItems.length > 0 ? (
              filteredOrderItems.map((product) => (
                <div
                  key={product.id}
                  className="flex flex-col md:flex-row items-center bg-white shadow-md border rounded p-4"
                >
                  <div className="flex-shrink-0">
                    <ImageWithLoader
                      imgClass="w-32 h-32"
                      src={product.productImage}
                      alt={product.productName}
                    />
                  </div>
                  <div className="flex-1 py-2 px-4">
                    <p
                      onClick={() => handleContent(product.id)}
                      className={`font-jaldi text-lg font-semibold tracking-widest cursor-pointer ${
                        content !== product.id ? "line-clamp-2" : ""
                      }`}
                    >
                      {product.productName}
                      <br />
                      <span className="text-sm font-medium">{product.categoryName}</span>
                    </p>
                    <p
                      onClick={() => handleContent(product.id)}
                      className={` ${
                        product.orderStatus == " Delivered"
                          ? "text-emerald-600"
                          : "text-amber-900"
                      } font-Inter font-medium text-md cursor-pointer ${
                        content !== product.id ? "line-clamp-2" : ""
                      }`}
                    >
                      {product.orderStatus}
                    </p>
                    <p className="text-red font-Inter font-medium text-sm">
                      <span>&#8377;</span> {product.price * product.quantity} /-
                    </p>
                  </div>
                  <div className="flex-shrink-0 py-2">
                    <Link
                      to="/Orderdetails"
                      state={{ orderId: product.orderId }}
                    >
                      View details
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500">No orders found.</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrdersPage;
