import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ProductContextStates } from '../../Context/ProductContext';

const Breadcrumb = ({ category, handleClear }) => {
    const location = useLocation();
    const pathname = location.pathname.split("/").filter((x) => x);
    const productContextStates = useContext(ProductContextStates);
    const [categories, setCategories] = useState([]);
    const [selectedProductCategoriesId, setSelectedProductCategoriesId] = useState([]);

    // console.log(category, 'category');

    useEffect(() => {
        // Clear categories when pathname changes
        setCategories([]);

        if (productContextStates?.selectedSubCategories?.listProductCategories) {
            setCategories(productContextStates.selectedSubCategories.listProductCategories);
        }
    }, []); 

    const handleProductCategory = (productCategory) => {
        setSelectedProductCategoriesId(productCategory);
        productContextStates.getProductCategoriesDataFilter(productCategory);
    };

    const shoWClear = productContextStates.productCategoryFilter;

    return (
        <div className='flex flex-wrap gap-2 justify-between items-center text-gray-600 text-10px md:text-base font-QuattrocentoSans cursor-pointer min-h-10 w-full'>
            <div className="flex gap-2 text-sm ">
                <Link to="/">
                    <p className='text-dark-brown cursor-pointer'>
                        Home <span>&gt;</span>
                    </p>
                </Link>
                {categories.length > 0 && categories.map((item) => (
                    <div key={item.id}>
                        <p className='capitalize hover:text-dark-brown'>
                            {(item.productCategory).toLowerCase()}
                            <span> &gt;</span>
                        </p>
                    </div>
                ))}
                {pathname.length > 0 && (
                    <p className='capitalize  text-gray-600 cursor-pointer'>
                        {pathname[pathname.length - 1]}
                    </p>
                )}
            </div>
            <div className={shoWClear && shoWClear !== 0 ? "block" : "hidden"}>
                <button className='px-1.5 py-0.5 text-16px border-2 rounded-5px shadow-inner bg-border-dark font-QuattrocentoSans text-dark-brown font-black' onClick={handleClear}>
                    clear
                </button>
            </div>
        </div>
    );
};

export default Breadcrumb;
