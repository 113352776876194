import React, { useContext, useEffect, useState } from "react";
import Loader from "../../acscomponents/Loader";
import { pluss, minus, heartblack, heartbrown } from "../../assets/svg/index";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "../../Context/AuthContext";
import { ProductContextStates } from "../../Context/ProductContext";
import {
  addFavourited,
  deleteFavourited,
} from "../../Networking/FavouriteApisAction";
import { getAllProducts } from "../../Networking/ProductApisAction";
import PriceSeeker from "../../acscomponents/PriceSeeker";
import Breadcrumb from "./Breadcrumb";
import ToastMessage from "../../acscomponents/ToastMessage";
import ImageWithLoader from "../../acscomponents/ImageLoaderComponent";
import { ToastMsgContext } from "../../Context/ToastMessageContext";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const productContextStates = useContext(ProductContextStates);
  const AuthUserContext = useContext(Auth);
  const [categories, setCategories] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const [displayStatus, setDisplayStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFavouriteList, setIsFavouriteList] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState(0);
  const [brandFilterData, setbrandFilterData] = useState(0);
  const [minimumPrice, setMinimumPrice] = useState(0);
  const [maximumPrice, setMaximumPrice] = useState(10000);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const location = useLocation();
  const [noDataFound, setNoDataFound] = useState(false);
  const selectedCategoryId = location?.state?.selectedCategoryId || null;
  const ProductCategoriesData = productContextStates.productCategoryFilter;
  const ToastMsgState = useContext(ToastMsgContext);

  useEffect(() => {
    setLoading(true);
    if (selectedCategoryId) {
      productContextStates.getAllProductCategories([]);
      setProducts(null);
      setCategoryId(selectedCategoryId);
    }
  }, [selectedCategoryId, products]);

  useEffect(() => {
    setLoading(true);
    productContextStates.getAllProductCategories([]);
    setProducts([]);
    if (!categoryId) return;
    const timeoutId = setTimeout(() => {
      const bodyData = {
        userId: AuthUserContext.customerId || 0,
        categoryId,
        subCategoryId: filteredCategory || 0,
        productCategory: 0,
        brandId: brandFilterData || 0,
      };
      setProducts([]);
      getAllProducts(bodyData)
        .then((response) => {
          const data = response?.data?.data || {};
          const code = response?.data?.code || 0;
          if (code === 200) {
            setCategories(data.listSubCategories || []);
            setBrandData(data.listBrands || []);
            const maxPrice = data.maxPrice;
            const minPrice = data.minPrice;
            setMaximumPrice(maxPrice);
            setMinimumPrice(minPrice);
            productContextStates.getMinprice(minPrice);
            productContextStates.getMaxPrice(maxPrice);
            const allProducts = data.listAllProductItems || [];
            setProducts(allProducts);
            productContextStates.getAllProductCategories(allProducts);
            setNoDataFound(allProducts.length === 0);
          } else {
            setProducts([]);
            setNoDataFound(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
          setProducts([]);
          setNoDataFound(true);
        });
    }, 700);

    return () => clearTimeout(timeoutId);
    setLoading(false);
  }, [
    categoryId,
    filteredCategory,
    brandFilterData,
    AuthUserContext.customerId,
    isFavouriteList,
  ]);

  // Handle price filter
  useEffect(() => {
    setLoading(true);
    const priceRangeMin =
      productContextStates.priceRangeMin?.[0] !== undefined
        ? productContextStates.priceRangeMin[0]
        : minimumPrice;
    const priceRangeMax =
      productContextStates.priceRangeMax?.[1] !== undefined
        ? productContextStates.priceRangeMax[1]
        : maximumPrice;

    const product = productContextStates.selectedSubCategories;
    const filteredProducts = product.filter(
      (product) =>
        product.discountedPrice >= priceRangeMin &&
        product.discountedPrice <= priceRangeMax
    );

    setProducts(filteredProducts.length > 0 ? filteredProducts : products);
    setNoDataFound(filteredProducts.length === 0);
    setLoading(false);
  }, [
    products,
    productContextStates.priceRangeMin,
    productContextStates.priceRangeMax,
    minimumPrice,
    maximumPrice,
  ]);

  const handleProductDetails = (selectedProductId) => {
    const url = `/ProductDetails?selectedProductId=${selectedProductId}&categoryId=${categoryId}`;
    window.open(url, "_blank");
  };

  const toggleDisplayStatus = () => {
    setDisplayStatus(!displayStatus);
  };

  const handleClear = () => {
    setFilteredCategory(0);
    setbrandFilterData(0);
    setMinimumPrice(0);
    setMaximumPrice(10000);
    setIsFilterActive(false);
    productContextStates?.getProductCategoriesDataFilter?.(0);
  };

  const handleCheckOut = (productId) => {
    if (!AuthUserContext.customerId) {
      ToastMsgState.getMessage("Kindly Login");
      ToastMsgState.getCode(201);
      ToastMsgState.getIsActive(true);
      return;
    }

    const isFavourite = isFavouriteList.includes(productId);

    if (!isFavourite) {
      const product = products.find((prod) => prod.id === productId);
      if (product) {
        const addFavoriteBodyData = {
          userId: AuthUserContext.customerId,
          productId: product.id,
          category: selectedCategoryId,
          subCategory: product.subCategoryId,
          productCategory: product.productCategoryId,
          brand: product.brandId,
          color: product.colorId,
          unit: product.unitId,
          productSize: product.productSizeId,
        };

        addFavourited(addFavoriteBodyData)
          .then((res) => {
            const { code, message } = res.data;
            ToastMsgState.getMessage(message);
            ToastMsgState.getCode(code);
            ToastMsgState.getIsActive(true);
            setIsFavouriteList((prev) => [...prev, productId]);
          })
          .catch((error) => {
            console.log("Error adding to Favourites:", error);
          });
      }
    } else {
      const deleteFavData = {
        productId: productId,
        userId: AuthUserContext.customerId,
      };

      deleteFavourited(deleteFavData)
        .then((res) => {
          const { code, message } = res.data;
          ToastMsgState.getMessage(message);
          ToastMsgState.getCode(code);
          ToastMsgState.getIsActive(true);
          setIsFavouriteList((prev) => prev.filter((id) => id !== productId));
        })
        .catch((error) => {
          console.log("Error removing from Favourites:", error);
        });
    }
  };

  return (
    <>
      {loading && <Loader className={loading ? "blur" : "blur-none"} />}
      <ToastMessage />
      <div className="flex flex-col md:flex-row bg-white gap-1 md:pb-16 pt-3 min-h-screen">
        {/* Left Part */}
        <h3 className="border-gray-300  px-2.5 py-1.5 font-bold text-20px text-dark-brown font-quicksand text-center block md:hidden">
          Filters
        </h3>
        <div className="flex md:flex-col md:flex-2 lg:flex-1 border-2 py-3 px-0.5 justify-start overflow-x-auto w-10vh md:w-auto md:min-h-screen border-b">
          <div className="flex justify-between md:border-b-2">
            <h3 className="border-gray-300  px-6 py-1.5 font-bold text-20px text-dark-brown font-quicksand hidden md:block">
              Filters
            </h3>
            <p
              className={`${
                isFilterActive ? "block" : "hidden"
              }  text-16px   font-QuattrocentoSans text-dark-brown font-black cursor-pointer`}
              onClick={handleClear}
            >
              clear
            </p>
          </div>

          {/* Categories */}
          <div className="px-6 py-1.5 md:border-b md:border-gray-300">
            <div className="flex justify-between">
              <h3 className="mb-5px text-12px opacity-80% cursor-pointer font-semibold font-Inter">
                CATEGORIES
              </h3>
            </div>
            <div className="cursor-pointer">
              {categories.map((itm) => (
                <p
                  key={itm.id}
                  className="text-nowrap md:text-wrap opacity-60% text-start font-jaldi"
                >
                  <span
                    className="px-1"
                    onClick={() => {
                      setFilteredCategory(itm.id);
                      setIsFilterActive(true);
                    }}
                  >
                    {itm.subCategoryName}
                  </span>
                </p>
              ))}
            </div>
          </div>

          {/* Brands */}
          {brandData.length > 0 && (
            <div className="px-6 py-2 md:border-b ">
              <div className="flex justify-between ">
                <div className="flex justify-center items-center gap-1">
                  <img
                    src={displayStatus ? minus : pluss}
                    onClick={toggleDisplayStatus}
                    className=" cursor-pointer mb-1.5 h-2.5 w-2.5"
                    alt="Toggle brands"
                  />
                  <h3 className="mb-5px text-12px opacity-80% cursor-pointer font-semibold font-Inter">
                    BRANDS
                  </h3>
                </div>
              </div>
              {displayStatus && (
                <div className="list-none flex flex-col gap-[3px] overflow-hidden h-auto">
                  {brandData.map((brand) => (
                    <div
                      key={brand.id}
                      className="whitespace-nowrap md:whitespace-normal opacity-60 text-start font-jaldi gap-2 "
                    >
                      <input
                        type="checkbox"
                        name={brand.id}
                        className="mt-0.5 h-3 w-4 opacity-100 cursor-pointer"
                        onChange={() => {
                          if (brandFilterData === brand.id) {
                            setbrandFilterData(null);
                            setIsFilterActive(false);
                          } else {
                            setbrandFilterData(brand.id);
                            setIsFilterActive(true);
                          }
                        }}
                        checked={brandFilterData === brand.id}
                      />
                      <label htmlFor={brand.brands} className="pl-1">
                        {brand.brands}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {/* Price Filter */}
          <div className="px-6 py-2 md:border-b md:border-gray-300">
            <h3 className="mb-5px text-12px opacity-80% cursor-pointer font-semibold font-Inter">
              Price
            </h3>
            <PriceSeeker
              min={minimumPrice}
              max={maximumPrice}
              getCurrentMin={(val) => setMinimumPrice(val)}
              getCurrentMax={(val) => setMaximumPrice(val)}
            />
          </div>
        </div>
        {/* Right Part */}
        <div className="flex-7 md:flex-6 ">
          <div className="bg-gray-100 px-4 text-sm font-semibold text-gray-600 font-QuattrocentoSans leading-3 py-1.5 my-1.5">
            <Breadcrumb category={categoryId} />
            <p className="capitalize text-gray-600 text-10px md:text-sm font-thin cursor-pointer">
              Showing{" "}
              <span className="font-semibold text-pretty text-dark-brown">
                1-{products.length}
              </span>{" "}
              results for{" "}
              <span className=" text-pretty">
                <span className="text-dark-brown font-semibold">
                  {products.length}{" "}
                </span>
                products
              </span>
            </p>
          </div>
          {products && products.length > 0 ? (
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 px-5">
                {products &&
                  products.map((product) => (
                    <div
                      key={product.id}
                      className="min-h-68 max-h-96 bg-slate-50 shadow-md"
                      target="blank"
                      onClick={() => handleProductDetails(product.id)} // Parent div onClick
                    >
                      <div className="p-2.5 cursor-pointer">
                        <ImageWithLoader
                          src={product.mainImage}
                          alt={product.product}
                          imgClass="object-contain border h-36 md:min-h-40 md:max-h-44 w-full p-2"
                        />
                      </div>
                      <div className="px-2.5 grid grid-cols-3 py-1.5 cursor-pointer">
                        <div className="flex-wrap col-span-2">
                          <p className="text-12px  font-jaldi text-black-opacity-80">
                            {product.categoryName}
                          </p>
                          <h3 className="font-semibold h-10 text-14px text-wrap w-44 overflow-hidden  font-QuattrocentoSans break-words">
                            {product.product.slice(0, 50)}
                          </h3>
                          <h4 className="text-red font-semibold tracking-wide py-1.5 px-2 text-12px md:text-md text-nowrap">
                            <span>&#8377;</span>{" "}
                            {product.priceWithGst ? product.priceWithGst : "1"}{" "}
                            /-
                          </h4>
                        </div>
                        <div className="col-span-1 text-end">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCheckOut(product.id);
                            }}
                            className={`p-1.5 rounded-5px ${
                              product.id
                                ? "bg-red-500 text-white"
                                : "bg-gray-200 text-gray-700"
                            }`}
                          >
                            <img
                              src={product.fav ? heartbrown : heartblack}
                              alt={
                                product.fav
                                  ? "Added to Favourites"
                                  : "Add to Favourites"
                              }
                              className="h-4 w-4"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <p className="text-center text-xl font-semibold">
              No products found
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductList;
